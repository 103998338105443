import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';

export class Magazin {

    /**
     * Constructor
    **/
    constructor () {
        /**
         * Call functions
        **/
        this.watchEvents();
    }

    /**
     * Function watchEvents():void
    **/
    watchEvents() {
        // Initialisiere Isotope ohne jQuery-Bridget
        const elements: NodeListOf<HTMLElement> = document.querySelectorAll('.c-magazin__content:not(.editmode .c-magazin__content)');
        elements?.forEach((element) => {
            let isotopeMagazin = new Isotope(element, {
                itemSelector: '.c-magazin__item',
                layoutMode: 'masonry',
                percentPosition: false,
                masonry: {
                    gutter: 32
                }
            });

            // Verwende imagesLoaded ohne jQuery
            imagesLoaded(element, () => {
                isotopeMagazin.layout();
            });
        });
    }
}
