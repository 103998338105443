export class ContentCols {

    /**
     * Constructor
    **/
    constructor() {
        this.initContentCols();
    }

    /**
     * Function initContentCols():void
     * initialises content col elements (brick)
    **/
    initContentCols() {
        const contentCols: NodeListOf<HTMLElement> = document.querySelectorAll('.c-content-cols:not(.editmode .c-content-cols)');

        contentCols.forEach(list => {
            const items: NodeListOf<HTMLElement> = list.querySelectorAll('.c-content-cols__headline');

            // Set initial height to auto
            items.forEach(item => item.style.height = 'auto');

            let maxHeight = 0;
            
            // Find the maximum height
            items.forEach(item => {
                const itemHeight = item.offsetHeight;

                if (itemHeight > maxHeight) {
                    maxHeight = itemHeight;
                }

                if (itemHeight === 1) {
                    list.classList.add('no-height');
                }
            });

            // Apply maximum height if no 'no-height' class
            items.forEach(item => {
                if (!list.classList.contains('no-height')) {
                    item.style.height = `${maxHeight}px`;
                }
            });
        });
    }
}
