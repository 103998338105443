/**
 * product-nav.ts
 * combines every functions that are used for product navigation
 *
**/

export class ProductNav {
    /**
     * Define class variables
    **/
    public xDirection:string = '';
    public yDirection:string = '';
    public obsoleteX:number = 0;
    public obsoleteY:number = 0;

    /**
     * Constructor
    **/
    constructor () {
        this.initItems();
        this.watchEvents();
    }

    /**
     * Function initItems():void
     * Set active class if url is similar
    **/
    protected initItems():void {
        /**
         * Initalise function variables
        **/

        const actualUrl: string = window.location.pathname + window.location.search;

        const items: NodeListOf<HTMLElement> = document.querySelectorAll('.js-s-product-nav__category-item, .js-s-product-nav__index-item');

        items?.forEach(item => {
            const aElements = item.querySelectorAll('a');
            if (aElements?.length) {
                aElements.forEach(aElement => {
                    if (aElement.href === actualUrl) {
                        aElement.classList.add('s-product-nav__item--active');
                    }
                });
            }
        });
    }

    /**
     * Function watchEvents():void
     * Watches all events of the base class
    **/
    protected watchEvents():void {
        /**
         * Initalise function variables
        **/

        /* Watch click for switching between navigations */

        (document.querySelectorAll('.js-s-product-nav__switchnav-item') as NodeListOf<HTMLElement>)?.forEach(navItem => {
            navItem.addEventListener('click touchend', () => {
                const requestedNavs: NodeListOf<HTMLElement> = document.querySelectorAll('.s-product-nav__' + navItem.dataset.nav);
                const navs = document.querySelectorAll('.js-s-product-nav__switchnav');
                navs?.forEach(nav => {
                    const siblings = nav.parentElement.children;

                    (Array.from(siblings)as Array<HTMLElement>)?.forEach((sibling: HTMLElement) => {
                        if (sibling != nav) {
                            sibling.style.display = 'none';
                        }
                    });
                });

                requestedNavs?.forEach(requestedNav => {
                    requestedNav.style.display = '';
                });

                document.querySelectorAll('.js-s-product-nav__switchnav-item')?.forEach(navItem => {
                    navItem.classList.remove('active');
                });

                navItem.classList.add('active');

            }, {passive: true});
        });

        /* Watch mouse movement and get direction */
        document.body.addEventListener('mouseover', (event: MouseEvent) => {
            this.getMouseDirection(event);
        }, {passive: true});


        /* Watch mousenter on navigation to show specific snippets */
        document.querySelectorAll('.js-s-product-nav__index-item')?.forEach(navIndexItem => {
            navIndexItem.addEventListener('mouseover', () => {
                const childs = navIndexItem.querySelectorAll('a');

                childs?.forEach(child => {
                    this.showSnippets(false, parseInt(child.dataset.navEntry));
                    child.classList.add('active');
                });

                document.querySelectorAll('.js-s-product-nav__index-item a')?.forEach(aElement => {
                    aElement.classList.remove('active');
                });
            }, {passive: true});
        });

        /* Watch mousenter on navigation to show specific snippets */
        document.querySelectorAll('.js-s-product-nav__category-item')?.forEach(categoryItem => {
            categoryItem.addEventListener('mouseenter', () => {
                const childs = categoryItem.querySelectorAll('a');
                childs?.forEach(child => {
                    this.showSnippets(true, parseInt(child.dataset.navEntry));
                    child.classList.add('active');
                });
                document.querySelectorAll('.js-s-product-nav__category-item a')?.forEach(aElement => {
                    aElement.classList.remove('active');
                });
            }, {passive: true});
        });

        /* Watch mousenter on all products link to show default content */
        document.querySelectorAll('.s-product-nav__switchnav-link')?.forEach(navLink => {
            navLink.addEventListener('mouseenter', () => {
                (document.querySelectorAll('.js-s-product-nav__category-productentry') as NodeListOf<HTMLElement>)?.forEach(productEntry => {
                    productEntry.style.display = 'none';
                });
                (document.querySelectorAll('.js-s-product-nav__category-productentry--default') as NodeListOf<HTMLElement>)?.forEach(productEntry => {
                    productEntry.style.display = '';
                });
                (document.querySelectorAll('.js-s-product-nav__snippet-item') as NodeListOf<HTMLElement>)?.forEach(productEntry => {
                    productEntry.style.display = 'none';
                });
                (document.querySelectorAll('.js-s-product-nav__snippet-item--default') as NodeListOf<HTMLElement>)?.forEach(productEntry => {
                    productEntry.style.display = '';
                });
            }, {passive: true});
        });
    }

    /**
     * Function showSnippets():void
     * Show snippets in navigation
     *
     * isCategoryNavigation: Option to show snippet in category- or indexnavigation
     *
    **/
    protected showSnippets(isCategoryNavigation:boolean = false, requestedSnippet:number = null):void {
        /**
         * Initalise function variables
        **/
        let snippetClass = ".js-s-product-nav__index-snippet";
        let productIsActive = false;

        if(isCategoryNavigation == true)
            snippetClass = ".js-s-product-nav__category-snippet";

        /* Check if a product snippet is already visible */
        if (document.querySelector(snippetClass)?.classList.contains(snippetClass + '--active')) {
            productIsActive = true;
        } else {
            productIsActive = false;
        }

        /* Skip show another element if mouse direction is right */

        if (this.xDirection == 'right' && productIsActive == true) {
            return;
        }


        /* Hide all snippets */
        const snippets: NodeListOf<HTMLElement> = document.querySelectorAll(snippetClass + ' .js-s-product-nav__snippet-item');
        snippets?.forEach(snippet => {
            snippet.style.display = 'none';
        });

        /* Show snippet of hovered product item */
        const hoverdSnippets: NodeListOf<HTMLElement> = document.querySelectorAll(snippetClass + ' .js-s-product-nav__snippet-item[data-snippetID="' + requestedSnippet + '"]');
        hoverdSnippets?.forEach(hoverdSnippet => {
            hoverdSnippet.style.display = '';
        });

        /* Show product entries of hovered product item in navigation */
        if(isCategoryNavigation == true) {
            const productEntries: NodeListOf<HTMLElement> = document.querySelectorAll('.js-s-product-nav__category-productentry');
            productEntries.forEach(productEntry => {
                productEntry.style.display = 'none';
            });
            const productEntrySnippets: NodeListOf<HTMLElement> = document.querySelectorAll('.js-s-product-nav__category-productentry[data-productEntryID="' + requestedSnippet + '"]');
            productEntrySnippets.forEach(productEntrySnippet => {
                productEntrySnippet.style.display = '';
            });
        }

        /* Add active class to snippet wrapper */
        document.querySelectorAll(snippetClass)?.forEach(snippet => {
            snippet.classList.add(snippetClass + '--active');
        });
    }

    /**
     * Function getMouseDirection():void
     * Get the mouse direction for x- and y-axis
    **/
    protected getMouseDirection(e: MouseEvent):void {

        /**
         * Initalise function variables
        **/
        // let self = this;

        /* Get vertical directions */
        if (this.obsoleteX < e.pageX) {
            this.xDirection = "right";
        } else {
            this.xDirection = "left";
        }

        /* Get horizontal directions */
        if (this.obsoleteY < e.pageY) {
            this.yDirection = "down";
        } else {
            this.yDirection = "up";
        }

        /* Save actual mouse position values */
        this.obsoleteX = e.pageX;
        this.obsoleteY = e.pageY;
    }
}
