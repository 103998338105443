import { Base } from "./1_base/base";
import { Search } from "./2_elements/search-form/search";
import { InfinityLoader } from "./2_elements/infinity-loader/infinity-loader";
import { Sidebar } from "./3_layout/page/sidebar/sidebar";
import { Flyout } from "./3_layout/page/flyout/flyout";
import { FlyoutNav } from "./3_layout/nav/flyout-nav";
import { PagetreeMain } from "./3_layout/nav/pagetree/pagetree-main";
import { Panel } from "./3_layout/page/panel/panel";
import { Panelnav } from "./3_layout/nav/panel-nav";
import { Brandswitcher } from "./3_layout/nav/brandswitcher";
import { Landingpagenavigation } from "./3_layout/nav/landingpagenavigation";
import { ToTop } from "./3_layout/page/to-top/to-top";
import { Header } from "./3_layout/header/header";
import { PageLoader } from "./3_layout/page/pageloader/pageloader";
import { Hero } from "./3_layout/content/hero/hero";
import { Slider } from "./4_components/slider/slider";
import { Magazin } from "./4_components/magazin/magazin";
import { ContentCols } from "./4_components/content-cols/content-cols";
import { Products } from "./5_xtensions/products/products";
import { ProductDetail } from "./5_xtensions/products/product-detail";
import { Newsletter } from "./5_xtensions/newsletter/newsletter";
import { Dealer } from "./5_xtensions/dealer/dealer";
import { DealerNavigation } from "./5_xtensions/dealer/dealer-navigation";
import { RoutingSnippets } from "./6_snippets/routing/routing";
import { Utility } from "./1_base/utility";
import { Jobs } from "./5_xtensions/jobs/jobs";
import { MapzDealerLocations } from "./5_xtensions/locations/mapz-dealer-locations";
import { LocationRequest } from "./5_xtensions/locations/location-request";
import { LocationSearchRequest } from "./5_xtensions/locations/location-search-request";
import { LocationSearch } from "./5_xtensions/locations/location-search";
import { Product360 } from "./4_components/product-360/product-360";
import { Video } from "./5_xtensions/video/video";

/**
 * main.ts
 * contains the whole logic of the project
 * uses classes that are defined
 * in elements,components or layouts
**/
// var $ = require('jquery');
// var locations = null;

/**
 * Document-ready function
**/

console.log('test');

document.addEventListener('DOMContentLoaded', () => {
    /**
     * Basics
     * general scripts required for multiple components and elements
    **/
    let base = new Base();

    /**
     * Sidebar
    **/
    let sidebar = new Sidebar();

    /**
     * Content-Cols - same Header height script
    **/
   let contentcols = new ContentCols();

     /**
     * Flyout
    **/
    let flyout = new Flyout();

    /**
     * Flyout-nav
    **/
   let flyoutNav = new FlyoutNav(flyout);

    /**
     * Pagetree-Main navigation for rootpage Design
    **/
   let pagetreeMain = new PagetreeMain();

    /**
     * Cookie message
    **/

   /**
     * Forms
    **/
    // let form = new Formfloat();

    /**
     * Panel
    **/
    let panel = new Panel();

    /**
     * Navigation
     * Parameters:
     * panel: Instance of panel
     * accordionMode: Close already open menu tabs
     * openCurrent: open active menu tab on load
     * animationDuration
     * overviewpagesPossible: Possiblity to open a menu point directly
    **/
    let panelnav = new Panelnav(panel, flyoutNav, true, true, 500, false);

    /**
     * Brand swithc navigation
    **/
    let brandswitcher = new Brandswitcher(panel);

    /**
     * Landingpagenavigation
     * Parameters:
     * animationDuration
    **/
    if(document.querySelector('.js-l-body')?.classList.contains('js-l-body--landingpage')) {
        let landingpagenavigation = new Landingpagenavigation();
    }

    /**
     * To-Top-Button
     * Parameters:
     * animationDuration
    **/
    let totop = new ToTop();

    /**
     * Header
    **/
   let header = new Header();

    /**
     * Pageloader
     * Parameters:
     * maximumTimeVisible
    **/
    let pageloader = new PageLoader();

    /**
     * Hero component
     * Parameters:
     * animationDuration
     * scrollFactor
    **/
    let hero = new Hero(600,0.7);

    /**
     * Slider
     * Parameters:
     * maximumTimeVisible
    **/
    let slider = new Slider();

    /* Watchlist */
    // let watchlist = new Watchlist();

    /**
     * Filter for tables
    **/
    // let filter = new Filter();

    /* Infinity loader */
    let infinityLoader = new InfinityLoader();

    /* Routing animations */
    let routing = new RoutingSnippets();

    /* Magazin isotope */
    let magazin = new Magazin();
    let products = new Products();
    let procutDetail = new ProductDetail();
    let newsletter = new Newsletter();
    let dealer = new Dealer();
    let search = new Search();
    let utility = new Utility();
    new Jobs();

    new DealerNavigation();
    let locationRequestService = new LocationRequest();
    new MapzDealerLocations(locationRequestService);
    const locationSearchRequest = new LocationSearchRequest()
    new LocationSearch(locationSearchRequest);

    /* 360 Viewer */
    new Product360();
    new Video();
});