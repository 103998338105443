/**
 * panel-menu.ts
 * Panel menu with an accordion mode und current opening submenu
 */

export class PagetreePanel {
  public accordionMode: boolean;
  public openCurrent: boolean;
  public animationDuration: number;
  public overviewpagesPossible: boolean;
  public menu: HTMLElement;

  /**
   * Constructor
   * Call 'new PagetreePanel(true, true, 500, false)'
   */
  constructor(accordionMode: boolean = true, openCurrent: boolean = true, animationDuration: number = 1000, overviewpagesPossible: boolean = false) {
    this.menu = document.querySelector('.js-l-pagetree-panel') as HTMLElement;
    this.accordionMode = accordionMode;
    this.openCurrent = openCurrent;
    this.animationDuration = animationDuration;
    this.overviewpagesPossible = overviewpagesPossible;

    // Initialize Menu
    this.initializeMenu();

    // Watch events
    this.watchEvents();
  }

  /**
   * Function initializeMenu(): void
   * Initialize menu
   * Set classes for open-states to elements
   */
  public initializeMenu(): void {
    if (!this.menu) return;

    // Append chevron icon to each element
    this.menu.querySelectorAll('li > a')?.forEach((a) => {
      const toggleSpan = document.createElement('span');
      toggleSpan.className = 'js-l-pagetree-panel__parent--toggle l-pagetree-panel__parent--toggle';
      a.appendChild(toggleSpan);
    });

    if (!this.overviewpagesPossible) {
      this.menu.querySelectorAll('li')?.forEach((li) => {
        const navigationItem = li.querySelector('a') as HTMLAnchorElement;
        const flyoutId = navigationItem.id;
        const elementId = flyoutId.substring(flyoutId.lastIndexOf('-') + 1);

        document.querySelectorAll('.js-l-pagetree-panel-sub > div')?.forEach((subDiv) => {
          const element = subDiv as HTMLElement;
          if (element.dataset.mainnavMain === elementId) {
            navigationItem.classList.add('js-l-pagetree-panel__parent--toggle');
          }
        });
      });
    }

    if (this.openCurrent) {
      this.menu.querySelectorAll('li.js-l-pagetree-panel__item--active')?.forEach((li) => {
        const a = li.querySelector('a') as HTMLAnchorElement;
        if (a.classList.contains('active-trail')) {
          li.classList.add('js-l-pagetree-panel__item-parent--open');
        }
      });
    }
  }

  /**
   * Function watchEvents(): void
   * Watches all events of the panelmenu and handles toggling
   */
  public watchEvents(): void {
    if (!this.menu) return;

    // Watch for clicks on menu-parent item and handle toggling of menus and submenus
    this.menu.querySelectorAll('.js-l-pagetree-panel__parent--toggle')?.forEach((toggleButton) => {
      toggleButton.addEventListener('click', (e: Event) => {
        e.preventDefault();
        const currentMenu = toggleButton.closest('li') as HTMLElement;

        // Get clicked page id
        const flyoutId = (currentMenu.querySelector('a') as HTMLAnchorElement).id;
        const elementId = flyoutId.substring(flyoutId.lastIndexOf('-') + 1);

        // Check if flyout is available for page id and add active class
        document.querySelectorAll('.js-l-pagetree-panel-sub > div')?.forEach((subDiv) => {
          const element = subDiv as HTMLElement;
          if (element.dataset.mainnavMain === elementId) {
            element.classList.add('l-pagetree-panel-sub__item--active');
          }
        });
      });
    });

    // Watch for clicks on sub header to close submenu
    document.querySelectorAll('.l-pagetree-panel-sub__header')?.forEach((header) => {
      header.addEventListener('click', () => {
        (header.closest('.l-pagetree-panel-sub__item--active') as HTMLElement)?.classList.remove('l-pagetree-panel-sub__item--active');
      });
    });
  }
}
