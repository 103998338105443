/**
 * pageloader.js
 * Logic of pageloader (spinner)
 */

export class PageLoader {
    /**
     * Define class variables
     * 
     */

    private maximumTimeVisible = 3000;

    constructor(maximumTimeVisible = 3000) {
        /**
         * Initialize local constructor variables
         */
        this.maximumTimeVisible = maximumTimeVisible;

        /**
         * Call functions
         */
        this.init();
    }

    /**
     * Initialize functions
     */
    init() {
        /**
         * Hide loader after page is fully loaded
         */
        window.addEventListener('load', () => {
            this.hideLoader();
        });

        /**
         * Hide loader after maximum time if page is loading too long
         */
        setTimeout(() => {
            this.hideLoader();
        }, this.maximumTimeVisible);
    }

    /**
     * Function hideLoader
     * Hide spinner after page is fully loaded
     */
    hideLoader() {
        const loader: HTMLElement = document.querySelector('.js-l-pageloader');
        if (loader) {
            loader.style.transition = 'opacity 0.1s';
            loader.style.opacity = '0';
            setTimeout(() => {
                loader.style.display = 'none';
            }, 100);
        }
    }
}
