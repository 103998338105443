/**
 * flyout-nav.js
 * Logic of flyout element
 */

export class Flyout {
    /**
     * Define class variables
     */

    private flyoutClass = 'l-flyout';
    private flyoutClassJS = 'js-l-flyout';
    private flyoutOpenAdditive = 'open';
    private flyoutElement = document.querySelector(`.${this.flyoutClassJS}`);
    private flyoutOpenClass = `${this.flyoutClass}--${this.flyoutOpenAdditive}`;

    constructor() {
    }

    /**
     * Function to open the flyout
     */
    openFlyout() {
        /* Add open class to flyout */
        if (this.flyoutElement) {
            this.flyoutElement.classList.add(this.flyoutOpenClass);
        }
        document.body.classList.add('js-l-body__flyout--open');

        // Timing needs to be 1ms longer than search-flyout animation
        if (window.innerWidth >= 992) {
            setTimeout(() => {
                const searchField = document.getElementById('search-field');
                if (searchField) {
                    searchField.focus();
                }
            }, 301);
        }
    }

    /**
     * Function to close the flyout
     */
    closeFlyout() {
        /* Remove open class from flyout */
        if (this.flyoutElement) {
            this.flyoutElement.classList.remove(this.flyoutOpenClass);
        }
        document.body.classList.remove('js-l-body__flyout--open');
        
        // Hide and show specific elements
        this.toggleVisibility('.js-s-product-nav__category-productentry', '.js-s-product-nav__category-productentry--default');
        this.toggleVisibility('.js-s-product-nav__snippet-item', '.js-s-product-nav__snippet-item--default');
    }

    /**
     * Helper function to toggle visibility of elements
     * @param {string} selectorToHide - Selector for elements to hide
     * @param {string} selectorToShow - Selector for elements to show
     */
    toggleVisibility(selectorToHide: string, selectorToShow: string) {
       ( document.querySelectorAll(selectorToHide) as NodeListOf<HTMLElement>)?.forEach(element => {
            element.style.display = 'none';
        });
        (document.querySelectorAll(selectorToShow)as NodeListOf<HTMLElement>)?.forEach(element => {
            element.style.display = 'block';
        });
    }
}
