/**
 * hero.ts
 * Logic of hero component
 */

import Isotope from 'isotope-layout';

export class Hero {

    /**
     * Initialize class variables
     **/
    public animationDuration: number;
    public scrollFactor: number;

    /**
     * Constructor
     **/
    constructor(animationDuration: number = 1000, scrollFactor: number = 0.7) {
        this.animationDuration = animationDuration;
        this.scrollFactor = scrollFactor;

        this.watchEvents();

        (document.querySelectorAll('.js-l-hero__routing-wrapper') as NodeListOf<HTMLElement>)?.forEach(isotopeElement => {
            if (isotopeElement) {
                const isotope = new Isotope(isotopeElement, {
                    itemSelector: '.js-l-hero__routing-item',
                    layoutMode: 'masonry',
                    percentPosition: true,
                    masonry: {
                        gutter: 32
                    }
                });

                setTimeout(() => {
                    isotope.layout();
                }, 500);
            }
        });
    }

    /**
     * Function watchEvents():void
     * Watches all events of the hero component and handles scrolling
     **/
    public watchEvents(): void {
        window.addEventListener('scroll', () => this.parallax());

        document.querySelectorAll('.js-hero__scroll')?.forEach((element) => {
            element.addEventListener('click', (event) => {
                const headerHeight = (document.querySelector('.js-l-hero__header') as HTMLElement)?.offsetHeight || 0;
                const targetElement = document.querySelector('.pimcore_hero')?.nextElementSibling as HTMLElement;
                if (targetElement) {
                    window.scrollTo({
                        top: targetElement.offsetTop - headerHeight,
                        behavior: 'smooth'
                    });
                }
                event.preventDefault();
            });
        });

        const heroVideoVolume = document.querySelector('.js-l-hero__video-volume') as HTMLVideoElement;
        if (heroVideoVolume) {
            heroVideoVolume.addEventListener('click', () => {
                heroVideoVolume.muted = !heroVideoVolume.muted;
                heroVideoVolume.classList.toggle('off');
            });
        }
    }

    /**
     * Function parallax():void
     * Set margin-top to text-box (small parallax effect)
     **/
    public parallax(): void {
        const scrolled = window.scrollY;
        document.querySelectorAll('.l-hero__design--full .l-hero__text')?.forEach((element) => {
            (element as HTMLElement).style.marginTop = `${scrolled * this.scrollFactor}px`;
        });
    }
}
