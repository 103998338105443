/**
 * flyout-nav.ts
 * Logic of pagetree navigation in flyout
 */

import { Flyout } from "../page/flyout/flyout";
import { PagetreeSub } from "./pagetree/pagetree-sub";
import { ProductNav } from "../../6_snippets/product-nav/product-nav";
import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";

export class FlyoutNav {
    protected flyout: Flyout;

    constructor(flyout: Flyout) {
        this.flyout = flyout;
        this.watchEvents();

        // Get instance of PagetreeSecondary
        const pagetreeSub = new PagetreeSub();

        // Get instance of ProductNav
        const productNav = new ProductNav();
    }

    protected toggleNav(): void {
        const openerElements = document.querySelectorAll('.js-l-flyout-nav__opener');

        openerElements?.forEach(opener => {
            opener.addEventListener('click', (e) => {
                const mainNavID = (opener as HTMLElement).dataset.mainnavMain;

                // Clear search input if present
                const searchField = document.querySelector('input.search-field.form-control') as HTMLInputElement;
                if (searchField) {
                    searchField.value = '';
                }

                document.querySelector('.l-header__overlay')?.classList.add('l-header__overlay--open');

                // Check if flyout is already open
                if (document.querySelector('.js-l-flyout')?.classList.contains('l-flyout--open')) {
                    // If opened menu item is clicked again
                    if (document.querySelector(`.l-pagetree-sub[data-mainnav-sub="${mainNavID}"]`)?.classList.contains('visible')) {
                        this.closeNav();
                        return;
                    }
                }

                // Remove active class from flyout opener
                document.querySelectorAll('.js-l-flyout-nav__opener')?.forEach(opener => {
                    opener.classList.remove('l-pagetree-main__item--open');
                });

                // Open flyout
                this.flyout.openFlyout();

                // Set current menu point active
                (opener as HTMLElement).classList.add('l-pagetree-main__item--open');

                // Hide all sub navigations of flyout navigation
                document.querySelectorAll('.l-pagetree-sub').forEach(subNav => {
                    (subNav as HTMLElement).style.display = 'none';
                });

                // Show navigation with selected ID
                document.querySelector(`.l-pagetree-sub[data-mainnav-sub="${mainNavID}"]`)?.classList.add('visible');

                // Initialize Isotope
                const isotopeMagazinFlyout = new Isotope('.s-product-nav__category-productentry .c-magazin__content', {
                    itemSelector: '.c-magazin__item',
                    layoutMode: 'masonry',
                    percentPosition: true,
                    masonry: {
                        gutter: 32,
                        fitWidth: true
                    }
                });

                // Layout after images have loaded
                imagesLoaded('.s-product-nav__category-productentry .c-magazin__content', () => {
                    isotopeMagazinFlyout.layout();
                });

                e.preventDefault();
            });
        });

        // Close menu on page content click
        const closeElements = document.querySelectorAll('.l-header__overlay, .l-header, .l-page__content, .l-footer');
        closeElements?.forEach(el => {
            el.addEventListener('click', () => {
                if (window.innerWidth >= 992) {
                    this.closeNav();
                }
            });
        });

        const closeNavButton = document.querySelector('.js-l-flyout-nav__close');
        if (closeNavButton) {
            closeNavButton?.addEventListener('click', () => {
                this.closeNav();
            });
        }
    }

    public closeNav(): void {
        // Remove active class from flyout opener
        document.querySelectorAll('.js-l-flyout-nav__opener')?.forEach(opener => {
            opener.classList.remove('l-pagetree-main__item--open');
        });

        document.querySelector('.l-header__overlay')?.classList.remove('l-header__overlay--open');

        // Close flyout
        this.flyout.closeFlyout();
    }

    /**
     * Watches all events of the FlyoutNav class
     **/
    protected watchEvents(): void {
        let windowWidth = window.innerWidth;
        this.toggleNav();

        window.addEventListener('resize', () => {
            const windowWidthNew = window.innerWidth;
            if (windowWidth !== windowWidthNew) {
                this.closeNav();
                windowWidth = windowWidthNew;
            }
        });
    }
}
