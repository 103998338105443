/**
 * navigation.ts
 * Logic of navigation
 */

export class Landingpagenavigation {
  /**
   * Initalise class variables
   **/
  public animationDuration: number;

  /**
   * Constructor
   **/
  constructor(animationDuration: number = 1000) {
    this.animationDuration = animationDuration;
    this.initializeMenu();
    this.watchEvents();
  }

  /**
   * Function initializeMenu(): void
   * Initialize menu
   * Create HTML elements for landing page navigation
   **/
  public initializeMenu(): void {
    const topicNav = document.querySelector('.js-landingpage__topic-nav') as HTMLElement;
    const panelNav = document.querySelector('.js-landingpage__panel-nav') as HTMLElement;

    if (topicNav) {
      topicNav.innerHTML = '<ul class="l-topic-nav"></ul>';
    }
    if (panelNav) {
      panelNav.innerHTML = '<ul class="l-pagetree-panel"></ul>';
    }

    document.querySelectorAll('main section')?.forEach((element: Element) => {
      const section = element as HTMLElement;
      const id = section.id;
      const anchorTitle = section.dataset.anchorTitle;

      if (id) {
        const listItem = `<li><a href="#${id}">${anchorTitle}</a></li>`;
        if (topicNav) {
          topicNav.querySelector('ul')?.insertAdjacentHTML('beforeend', listItem);
        }
        if (panelNav) {
          panelNav.querySelector('ul')?.insertAdjacentHTML('beforeend', listItem);
        }
      }
    });

    this.setActive();
  }

  /**
   * Function watchEvents(): void
   * Watches all events of the landing page navigation and handles scrolling
   **/
  public watchEvents(): void {
    const topicNavLinks: NodeListOf<HTMLElement> = document.querySelectorAll('.js-landingpage__topic-nav li a, .l-pagetree-panel li a');
    topicNavLinks?.forEach((link) => {
      link.addEventListener('click', (event: MouseEvent) => {
        const target = event.currentTarget as HTMLAnchorElement;
        const anchor = target.getAttribute('href');
        const targetElement = document.querySelector(anchor) as HTMLElement;
        if (targetElement) {
          const anchorScrollTop = targetElement.offsetTop;
          const anchorScrollPosition = anchorScrollTop - 150;

          if (document.body.classList.contains('js-l-panel-nav--open')) {
            const backdrop = document.querySelector('.l-panel-nav__backdrop') as HTMLElement;
            backdrop?.click();
          }

          window.scrollTo({
            top: anchorScrollPosition,
            behavior: 'smooth',
          });
          event.preventDefault();
        }
      });
    });

    window.addEventListener('scroll', () => {
      this.setActive();
    });
  }

  /**
   * Function setActive(): void
   * Set active state of menu-items
   **/
  public setActive(): void {
    const links = document.querySelectorAll('.js-landingpage__topic-nav li a, .l-pagetree-panel li a');
    const scrollTop = window.scrollY + 166;

    links?.forEach((link) => {
      const target = link.getAttribute('href');
      const targetElement = document.querySelector(target) as HTMLElement;
      if (targetElement) {
        const anchorScrollTop = targetElement.offsetTop;
        const parentItem = link.parentElement as HTMLElement;

        if (scrollTop > anchorScrollTop + targetElement.offsetHeight) {
          // Do nothing
        } else {
          if (anchorScrollTop < scrollTop) {
            parentItem.classList.add('active');
            const prevItem = parentItem.previousElementSibling as HTMLElement;
            prevItem?.classList.remove('active');
          } else {
            parentItem.classList.remove('active');
          }
        }
      }
    });
  }
}
