export class ToTop {

    /**
     * Define class variables
    **/
    public animationDuration: number;

    /**
     * Constructor
    **/
    constructor(animationDuration: number = 900) {
        /**
         * Set parameters
        **/
        this.animationDuration = animationDuration;

        /**
         * Call functions
        **/
        this.checkVisibility();
        this.watchEvents();
    }

    /**
     * Function checkVisibility():void
     * set/reset to-top classes based on scroll position
    **/
    checkVisibility(): void {
        if (window.scrollY > 100) {
            document.querySelector('.js-l-to-top')?.classList.add('l-to-top--active');
        } else {
            document.querySelector('.js-l-to-top')?.classList.remove('l-to-top--active');
        }
    }

    /**
     * Function watchEvents():void
     * Watches all events for scroll and click actions
    **/
    watchEvents(): void {
        // Toggle class based on scroll position
        window.addEventListener('scroll', () => {
            this.checkVisibility();
        });

        // Scroll to top on button click
        document.querySelector('.js-l-to-top')?.addEventListener('click', (event) => {
            event.preventDefault();
            this.scrollToTop();
        });
    }

    /**
     * Function scrollToTop():void
     * Smoothly scroll the page to the top
    **/
    scrollToTop(): void {
        const scrollDuration = this.animationDuration;
        const scrollStep = -window.scrollY / (scrollDuration / 15);
        const scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    }
}
