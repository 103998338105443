declare var grecaptcha: any;

export class Utility {
    constructor() {
        this.sendFormWithCaptcha();
    }

    private sendFormWithCaptcha() {
        (document.querySelectorAll('form[name="eebundles_contactsbundle_contact_form"]') as NodeListOf<HTMLFormElement>)?.forEach((form: HTMLFormElement) => {
            form.addEventListener('submit', (event) => {
                const formData = new FormData(form);

                event.preventDefault();

                grecaptcha.ready(function() {
                    grecaptcha.execute('6LfCGTccAAAAAGBMN4bsdII3RHaDV41wtJJqxeMN', {action: 'submit'}).then(function(token: string) {
                        formData.append('token', token);

                        const data = new URLSearchParams((formData as any));

                        fetch(form.action,
                        {
                            method: 'POST',
                            mode: 'no-cors',
                            body: data,
                            redirect: 'follow'
                        }).then(response => {
                            if (response.redirected) {
                                window.location.href = response.url;
                            }
                        });
                    });
                });
            });
        });
    }
}