import { config } from "./config";
import { ICategories } from "./categories.dto";

export class DealerNavigation {
  private subNav: HTMLElement = document.querySelector(".js-dealer-subnav");
  private flagshipButton: HTMLElement = this.subNav?.querySelector(".js-dealer-toggle-flagship");
  private categoriesButton: HTMLElement = this.subNav?.querySelector(".js-dealer-categories");
  private categoriesLink: HTMLElement = this.subNav?.querySelector(".js-dealer-categories-link");
  private categoriesDropdown: HTMLElement = this.subNav?.querySelector(".js-dealer-categories-dropdown");

  constructor() {
    if ((this.subNav, this.categoriesButton)) {
      this.filterHandler(this.subNav, this.categoriesButton, this.categoriesDropdown, this.categoriesLink);
    }

    if (this.flagshipButton) {
      this.flagshipHandler(this.flagshipButton);
    }
  }

  /**
   * Handles the logic for the filter dropdown
   * @param subNav the subnavigation where the dropdown is located
   * @param categoriesButton the button which activates the dropdown
   * @param categoriesDropdown the html of the dropdown itself
   */
  private filterHandler(subNav: HTMLElement, categoriesButton: HTMLElement, categoriesDropdown: HTMLElement, categoriesLink: HTMLElement) {
    if (categoriesDropdown) {
      const folderId = parseInt(categoriesDropdown.dataset.folderId);
      categoriesButton.style.cursor = "wait";
      categoriesLink.style.cursor = "wait";

      const requestBody = {};
      const requestUrl = window.location.protocol + "//" + window.location.host + config.categoriesUrl + folderId;
      const request = new Request(requestUrl, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: new Headers({ "Content-Type": "application/json" }),
      });
      fetch(request)
        .then((response) => {
          categoriesButton.style.cursor = "pointer";
          categoriesLink.style.cursor = "pointer";
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Categories not found. Please try again");
          }
        })
        .then((response: Array<ICategories>) => {
          this.populateDropdown(response, categoriesDropdown);
        })
        .catch((error) => {
          console.error(error);
        });
        document.addEventListener('click', (event) => {
          if (!event.composedPath().includes(categoriesDropdown) && !categoriesDropdown.classList.contains('d-none') && !event.composedPath().includes(categoriesButton) &&  !event.composedPath().includes(categoriesLink)) {
            categoriesDropdown.classList.add('d-none');
          }
        })
    }

    const wrapper = this.subNav.querySelector(".x-dealer__nav-categories-wrapper");
        wrapper.addEventListener("click", (event) => {
          if (event.target == wrapper || event.target === categoriesButton || event.target === categoriesLink) {
            event.preventDefault();
            if (categoriesDropdown.classList.contains("d-none")) {
              categoriesDropdown.classList.remove("d-none");
            } else {
              categoriesDropdown.classList.add("d-none");
            }
          }
        });

    document.addEventListener("meinl:dealer-place-changed", (_) => {
        subNav.classList.remove("d-none");
      },
      { passive: true }
    );
  }

  /**
   * Populates the filter dropdown with the values to be filtered.
   * @param categories an array of categories from the backend
   * @param dropdown the actual dropdown html element
   */
  private populateDropdown(
    categories: Array<ICategories>,
    dropdown: HTMLElement
  ) {
    const itemWrapper: HTMLElement = dropdown.querySelector(".js-dealer-categories-item-wrapper");
    const dummyItem: HTMLElement = dropdown.querySelector(".js-dealer-categories-item-dummy");
    const checkbox: HTMLInputElement = dummyItem.querySelector(".js-dummy-input");
    const label: HTMLLabelElement = dummyItem.querySelector(".js-dummy-label");

    categories.forEach((category) => {
      checkbox.name = category.name;
      checkbox.value = category.id;
      checkbox.id = category.name;
      label.innerHTML = `<div><span>${category.name}</span></div>`;
      label.htmlFor = category.name;

      const clonedDummy = dummyItem.cloneNode(true);
      itemWrapper.append(clonedDummy);
    });

    checkbox.name = "null";
    checkbox.value = "null";
    checkbox.id = "null";
    label.innerHTML = "<div><span>null</span></div>";
    label.htmlFor = "null";

    (Array.from(itemWrapper.children) as Array<HTMLElement>).forEach((child: HTMLElement) => {
      if (child.classList.contains("d-none")) {
        child.classList.remove("d-none");
      }
    });
    this.applyFilter(dropdown);
    this.resetFilter(dropdown);
  }

  private applyFilter(categoriesDropdown: HTMLElement) {
    const applyButton: HTMLElement = categoriesDropdown.querySelector(".js-filter-apply");
    const counterElement: HTMLElement = this.subNav.querySelector( ".js-dealer-categories-counter");
    const allFilterNodes = categoriesDropdown.querySelector(".js-dealer-categories-item-wrapper").children;
    let valueArray: Array<string> = new Array();
    let valueResponseArray: Array<number> = new Array();

    applyButton.addEventListener("click", (event) => {
      event.preventDefault();
      valueArray = [];
      valueResponseArray = new Array();
      Array.from(allFilterNodes).forEach((children) => {
        const input: HTMLInputElement = children.querySelector("input");
        if (input.checked && input.name != "all") {
          valueArray.push(input.value);
          valueResponseArray.push(parseInt(input.value))
        }
      });

      if (valueArray.length > 0 && valueArray.length != allFilterNodes.length ) {
        counterElement.innerText = valueArray.length.toString();
        counterElement.classList.remove("d-none");
      } else {
        counterElement.innerText = "0";
        counterElement.classList.add("d-none");
      }

      document.dispatchEvent(new CustomEvent("meinl:dealer-filter-applied", { detail: valueResponseArray }));
      categoriesDropdown.classList.add("d-none");
    });
  }

  private resetFilter(categoriesDropdown: HTMLElement) {
    const resetButton: HTMLElement = categoriesDropdown.querySelector(".js-filter-clear");
    const counterElement: HTMLElement = this.subNav.querySelector(".js-dealer-categories-counter");
    const allFilterNodes = categoriesDropdown.querySelector(".js-dealer-categories-item-wrapper").children;

    resetButton.addEventListener("click", (event) => {
      event.preventDefault();
      Array.from(allFilterNodes).forEach((children) => {
        const input: HTMLInputElement = children.querySelector("input");
        input.checked = true;
      });
      counterElement.innerText = "0";
      counterElement.classList.add("d-none");

      document.dispatchEvent(
        new CustomEvent("meinl:dealer-filter-applied", { detail: [] })
      );
      categoriesDropdown.classList.add("d-none");
    });

  }

  /**
   * Handles the flagship button behaviour
   * @param flagshipButton the html of the flagship button
   */
  private flagshipHandler(flagshipButton: HTMLElement) {
    flagshipButton.style.cursor = "wait";
    let flagshipFound: Array<ICategories>;
    const folderId = parseInt(this.categoriesDropdown.dataset.folderId);
    const requestBody = {};
    const requestUrl = window.location.protocol + "//" + window.location.host + config.typesUrl + folderId;
    const request = new Request(requestUrl, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    fetch(request)
      .then((response) => {
        flagshipButton.style.cursor = "pointer";
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Categories not found. Please try again");
        }
      })
      .then((response: Array<ICategories>) => {
        flagshipFound = response;
      })
      .catch((error) => {
        console.error(error);
      });
    flagshipButton.addEventListener("click", (event) => {
      event.preventDefault();
      if (flagshipButton.classList.contains("active")) {
        flagshipButton.classList.remove("active");
        window.localStorage.removeItem('flagship');
        document.dispatchEvent(new CustomEvent("meinl:dealer-flagship-applied", { detail: [] }));
      } else {
        flagshipButton.classList.add("active");
        window.localStorage.setItem('flagship', 'true');
        document.dispatchEvent(new CustomEvent("meinl:dealer-flagship-applied", {detail: flagshipFound}));
      }
    });
  }
}
