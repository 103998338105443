/**
 * base.ts
 *
 **/

import 'bootstrap';
import 'tablesorter';
import picturefill from 'picturefill';
import Stickyfill from 'stickyfilljs';
import ClipboardJS from 'clipboard';

export class Base {
  /**
   * Constructor
   **/

  constructor() {
    this.initTableSorter();
    this.initStickyElements();
    picturefill();
    this.initClipboardElements();
    this.watchEvents();
  }

  /**
   * Function backLink():void
   * Creates/get a back link from document referrer or history
   **/

  protected backLink(): void {
    document.querySelectorAll('.js-base__back-link')?.forEach((element) => {
      element.addEventListener('click', () => {
        const backURL = (element as HTMLElement).dataset.backUrl;
        if (document.referrer.includes(window.location.host)) {
          history.back();
        } else {
          window.location.href = backURL || '#';
        }
      });
    });
  }

  /**
   * Function anchorAnimation():void
   * Initializes anchor animation
   **/

  protected anchorAnimation(): void {
    document.querySelectorAll('.e-rte a[href^="#"]')?.forEach((anchor) => {
      anchor.addEventListener('click', (event: Event) => {
        event.preventDefault();

        if ((anchor as HTMLElement).classList.contains('js-base__back-link')) {
          return;
        }

        const targetId = (anchor as HTMLAnchorElement).getAttribute('href');
        const target = document.querySelector(targetId);

        if (!(anchor as HTMLElement).classList.contains('js-base__noAnchor') && target) {
          window.scrollTo({
            top: target.getBoundingClientRect().top + window.scrollY - 200,
            behavior: 'smooth',
          });
        }
      });
    });

    if (window.location.hash) {
      const target = document.querySelector(window.location.hash);

      if (target) {
        window.scrollTo({
          top: target.getBoundingClientRect().top + window.scrollY - 200,
          behavior: 'smooth',
        });
      }
    }
  }

  /**
   * Function initBootstrapPopover():void
   * Initializes Bootstrap popover
   **/

  protected initBootstrapPopover(): void {
    document.querySelectorAll('[data-toggle="popover"]')?.forEach((popover) => {
      // Assuming you have already included Bootstrap's JS for popover functionality
      (popover as any).popover();
    });

    document.querySelectorAll('.e-button__clipboard')?.forEach((button) => {
      button.addEventListener('click', () => {
        document.querySelectorAll('[data-toggle="popover"]').forEach((popover) => {
          (popover as any).popover('hide');
        });

        (button as HTMLElement).classList.add('e-button__clipboard--copy');
        setTimeout(() => {
          (button as HTMLElement).classList.remove('e-button__clipboard--copy');
        }, 2000);
      });
    });
  }

  /**
   * Function initTableSorter():void
   * Initializes tablesorter
   **/

  protected initTableSorter(): void {
    document.querySelectorAll('.e-rte table')?.forEach((table) => {
      const tableContent = table.innerHTML;
      const tableID = table.id;
      const tableClass = table.className;

      table.replaceWith(`
                <div class="table-responsive">
                    <table id="${tableID}" class="${tableClass}">${tableContent}</table>
                </div>
            `);

      const tableElement = document.querySelector(`#${tableID}`) as HTMLElement;
      if (tableElement) {
        const rowCount = tableElement.querySelectorAll('tr').length;

        if (rowCount >= 5) {
          (tableElement as any).tablesorter();
        }
      }
    });
  }

  /**
   * Function initStickyElements():void
   * Initializes Stickyfill
   **/

  protected initStickyElements(): void {
    const elements: NodeListOf<HTMLElement> = document.querySelectorAll('.sticky');
    Stickyfill.add(elements);
  }

  /**
   * Function initClipboardElements():void
   * Initializes ClipboardJS
   **/

  protected initClipboardElements(): void {
    new ClipboardJS('.e-button__clipboard');
  }

  /**
   * Function formErrorScrollHandler():void
   * Scrolls to the error
   **/

  protected formErrorScrollHandler(): void {
    document.querySelectorAll('*[type=submit]')?.forEach((button) => {
      button.addEventListener('click', () => {
        document.querySelectorAll('input, textarea, select')?.forEach((input) => {
          input.addEventListener(
            'focus',
            () => {
              window.scrollTo({
                top: (input as HTMLElement).getBoundingClientRect().top + window.scrollY - 200,
                behavior: 'smooth',
              });
            },
            { once: true }
          );
        });
      });
    });
  }

  /**
   * Function isElementInViewport():void
   * Checks if elements are in the viewport
   **/

  protected isElementInViewport(): void {
    document.addEventListener('DOMContentLoaded', () => {
      document.querySelectorAll('.js-viewport')?.forEach((viewport) => {
        const checkVisibility = () => {
          const thisOffset = viewport.getBoundingClientRect();
          const windowHeight = (window.innerHeight / 10) * 12;
          const scrollPos = window.scrollY;
          const visiblePoint = scrollPos + windowHeight;

          if (thisOffset.top < visiblePoint) {
            viewport.classList.add('js-viewport--visible');
          }
        };

        checkVisibility();
        window.addEventListener('scroll', checkVisibility);
      });
    });
  }

  protected accordionScroll(): void {
    document.addEventListener('DOMContentLoaded', () => {
      document.querySelectorAll('.c-accordion__title')?.forEach((title) => {
        title.addEventListener('click', () => {
          setTimeout(() => {
            const offsetTop = title.getBoundingClientRect().top + window.scrollY - 200;

            window.scrollTo({
              top: offsetTop,
              behavior: 'smooth',
            });
          }, 300);
        });
      });
    });
  }

  /**
   * Function watchEvents():void
   * Watches all events of the base class
   **/

  protected watchEvents(): void {
    this.backLink();
    this.anchorAnimation();
    this.initBootstrapPopover();
    this.formErrorScrollHandler();
    this.isElementInViewport();
    this.accordionScroll();
  }
}
