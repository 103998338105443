import 'video.js';
import videojs from 'video.js/dist/video.es';

export class Video {
  private videoElements: NodeListOf<HTMLVideoElement> = document.querySelectorAll('.video-js');

  constructor() {
    this.videoElements?.forEach(element => {
      this.handleVideos(element);
    });
  }

  private handleVideos(video: HTMLVideoElement) {
    const player = videojs(video);

    player.on('play', () => {
      this.videoElements?.forEach(element => {
        const newPlayer = videojs(element);
        if(player != newPlayer) newPlayer.pause();
      });
    });

    // Event-Listener für den Vollbildmodus mit Video.js-spezifischem Event
    player.on('fullscreenchange', () => {
      const sdSource = video.querySelector('source[data-source="sourcesd"]') as HTMLSourceElement;
      const sourceHd = video.querySelector('source[data-source="sourcehd"]') as HTMLSourceElement;
      const currentTime = player.currentTime();

      if (sdSource && sourceHd) {
        if (player.isFullscreen()) {
          player.src({ type: 'video/mp4', src: sourceHd.src });
          player.currentTime(currentTime);
          player.play();
          player.addClass('fullscreen');

        } else {
          player.src({ type: 'video/mp4', src: sdSource.src });
          player.currentTime(currentTime);
          player.play();
          player.removeClass('fullscreen');
        }
      }
    });
  }
}

document.addEventListener("DOMContentLoaded", function () {
  function addVideoSlideClassIfClassExists() {
    // Alle Elemente mit der Klasse x-products-intro__slider-videos-toggle auswählen
    const elementsWithClass: NodeListOf<HTMLElement> = document.querySelectorAll('.x-products-intro__slider-videos-toggle');

    // Für jedes passende Element
    elementsWithClass?.forEach((element: HTMLElement) => {
      // Suchen Sie das übergeordnete slick-slide-Element (2 Ebenen höher)
      const slickSlideElement = findAncestorWithClass(element, 'slick-slide');

      // Wenn ein slick-slide-Element gefunden wurde, fügen Sie die Klasse video-slide hinzu
      if (slickSlideElement) {
        slickSlideElement.classList.add('video-slide');
      }
    });
  }

  // Hilfsfunktion, um das übergeordnete Element mit einer bestimmten Klasse zu finden
  function findAncestorWithClass(element: HTMLElement, className: string) {
    let parent = element.parentElement;
    while (parent) {
      if (parent.classList.contains(className)) {
        return parent;
      }
      parent = parent.parentElement;
    }
    return null; // Wenn kein passendes übergeordnetes Element gefunden wurde
  }

  // Die Funktion aufrufen, um das Verhalten auszuführen
  addVideoSlideClassIfClassExists();

});
