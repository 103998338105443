/**
 * routing.ts
 * handels routing snippets
 *
**/

export class RoutingSnippets {

	/**
	 * Define class variables
	**/

	private delayTime: number = 200;

	/**
	 * Constructor
	**/

	constructor () {
		this.watchEvents();
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the base class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables
		**/

		let timeout:any = null;
		let touchmoved:any;


		/* Watch click event */

		const routings: NodeListOf<HTMLElement> = document.querySelectorAll('.s-routing');

		routings?.forEach(routing => {
			const parentSection = routing.closest('section');

			routing.addEventListener('click', (event: Event) => {
				const supportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints;

				if(supportsTouch == true && !routing.classList.contains('is-active')) {

					/* Disable default actions */

					event.stopImmediatePropagation();
					event.preventDefault();


					/* Activate hover / active state */

					this.activate(parentSection, routing);
				} else {

					/* Deactive hover / active state */

					// self.deactivate(parentSection, element);
				}

			}, {passive: true});

			routing.addEventListener('mouseover', () => {
				const supportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints;

				clearTimeout(timeout);

				/* If actual element has no active state */

				if(!routing.classList.contains('is-active') && !supportsTouch) {
					timeout = setTimeout(() => {

						/* Activate hover / active state */

						this.activate(parentSection, routing);
					}, this.delayTime);
				}
			}, {passive: true});

			routing.addEventListener('mouseleave', (event: Event) => {

				clearTimeout(timeout);


				/* Deactive hover / active state */

				timeout = setTimeout(() => {
					this.deactivate(parentSection, routing);
				}, this.delayTime)
			}, {passive: true});
		});


		/* Detect click / touchend without move */

		const html: HTMLElement = document.querySelector('html');

		html?.addEventListener('touchend', (event: Event) => {
			if (touchmoved == true) {
				return;
			}

			const routingsActive = document.querySelectorAll('.s-routing.is-active');
			const routings = document.querySelectorAll('.s-routing');

			routings?.forEach(routing => {
				if (routingsActive?.length) {
					/* If the target of the click isn't the container nor a descendant of the container */
					if (!(routing == event.target) && routing.contains(event.target as Node)) {
						this.removeClasses(html);
					}
				}
			});
		}, {passive: true});

		html?.addEventListener('touchmove', () => {
			touchmoved = true;
		});
		html?.addEventListener('touchstart', () => {
			touchmoved = false;
		});

		/* Call back link routing watcher */

		// self.backLinkRouting();
	}



	/**
	 * Function deactivate(parentSection:any, element:any):void
	**/

	protected deactivate(parentSection:any, element:any):void {
		/**
		 * Initalise function variables
		**/

		/* Remove classes */

		this.removeClasses(parentSection);


		/* Stop video */

		this.stopVideo(element);
	}



	/**
	 * Function active(parentSection:any, element:any):void
	**/

	protected activate(parentSection:any, element:any):void {
		/**
		 * Initalise function variables
		**/


		/* Remove classes */

		this.removeClasses(parentSection);

		/* Setup element */

		this.setupElements(parentSection, element);


		/* Play video */

		this.playVideo(element);
	}



	/**
	 * Function removeClasses(parentSection:any):void
	**/

	protected removeClasses(parentSection: HTMLElement):void {
		parentSection.querySelectorAll('.s-routing')?.forEach(routing => {
			routing.classList.remove('is-inactive');
			routing.classList.remove('is-active');

			routing.querySelectorAll('.s-routing__video')?.forEach(routingVideo => {
				routingVideo.classList.remove('s-routing__video--active');
			});
		});
	}



	/**
	 * Function setupElements(parentSection:any, element:any):void
	**/

	protected setupElements(parentSection: HTMLElement, element: HTMLElement):void {

		/* Add inactive classes */
		parentSection.querySelectorAll('.s-routing')?.forEach(routing => {
			routing.classList.add('is-inactive');
		});

		element.classList.remove('is-inactive');
		element.classList.add('is-active');

		/* Add video active class */
		setTimeout(function(){
			element.querySelectorAll('.s-routing__video')?.forEach(routingVideo => {
				routingVideo.classList.add('s-routing__video--active');
			});
		}, 600);
	}



	/**
	 * Function playVideo(element:any):void
	**/

	protected playVideo(element: HTMLElement):void {

		/* Set video element from overgiven parent */

		const videos: NodeListOf<HTMLVideoElement> = element.querySelectorAll('video');

		/* Reset and play video  */

		if (videos?.length) {
			videos[0].currentTime = 0;
			videos?.forEach(video => {
				video.play();
			});
		}
	}



	/**
	 * Function stopVideo(element:any):void
	**/

	protected stopVideo(element: HTMLElement):void {
		const videos: NodeListOf<HTMLVideoElement> = element.querySelectorAll('video');

		videos.forEach(video => {
			video.pause();
		});
	}
}
