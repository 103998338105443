export class Search {

    private inputField: HTMLInputElement;
    private inputFieldSKU: HTMLInputElement;
    private searchCollectionSource: Number;
    private categories = new Array();
    private defaultCollectionId: any;
    private currentPageBrand: string;
    private currentBrandIconClass: string;
    private defaultCollectionTeaser = document.querySelector('.s-flyout--search .s-flyout__teaser');
    private defaultCollectionFirst = document.querySelectorAll('.s-flyout--search .s-flyout__search-snippet').item(0) as any;
    private defaultCollectionSecond = document.querySelectorAll('.s-flyout--search .s-flyout__search-snippet').item(1) as any;
    private defaultCollectionTeaserInnerHTML: any;
    private defaultCollectionFirstInnerHTML: any;
    private defaultCollectionFirstStyle: any;
    private defaultCollectionFirstClass: any;
    private defaultCollectionSecondInnerHTML: any;
    private defaultCollectionSecondStyle: any;
    private defaultCollectionSecondClass: any;
    private teaserPosition = 2 as Number; // 0 = Search field, 1 = tags, 2 = firstCollection, 3 = secondCollection; Appends after the element.

    constructor() {
        const categoriesElements:NodeListOf<HTMLInputElement> = document.querySelectorAll('.searchCategory');
        this.defaultCollectionId = document.querySelector('[data-default]');
        this.currentBrandIconClass = 'icon-icon-meinl-logo';
        if (this.defaultCollectionId) {
            this.currentPageBrand = this.defaultCollectionId?.dataset?.brand;
            this.defaultCollectionId = this.defaultCollectionId?.dataset?.default;

            switch (this.currentPageBrand) {
                case 'ortega':
                    this.currentBrandIconClass = 'icon-icon-ortega-logo';
                break;
            }

            /* safe default HTML */
            this.defaultCollectionTeaserInnerHTML = this.defaultCollectionTeaser.innerHTML;

            this.defaultCollectionFirstInnerHTML = this.defaultCollectionFirst.innerHTML;
            this.defaultCollectionFirstStyle = this.defaultCollectionFirst.style.display;

            this.defaultCollectionSecondInnerHTML = this.defaultCollectionSecond.innerHTML;
            this.defaultCollectionSecondStyle = this.defaultCollectionSecond.style.display;

            if (this.defaultCollectionFirst.classList.contains('active')) {
                this.defaultCollectionFirstClass = 'active';
            } else {
                this.defaultCollectionFirstClass = 's-flyout__search-default';
            }
            if (this.defaultCollectionSecond.classList.contains('active')) {
                this.defaultCollectionSecondClass = 'active';
            } else {
                this.defaultCollectionSecondClass = 's-flyout__search-default';
            }

            for (let i = 0; i < categoriesElements.length; ++i) {
                this.categories.push(categoriesElements[i].value);
            }
            this.searchCollectionSource = Number((<HTMLInputElement>document.getElementById('searchCollectionSource')).value);
            this.inputField = <HTMLInputElement>document.getElementById('search-field') ?? null;
            if (this.inputField != null) {
                this.inputField.addEventListener('keyup', this.handleInput.bind(this), false);
            }

            this.inputFieldSKU = <HTMLInputElement>document.getElementById('search-sku') ?? null;
            if (this.inputFieldSKU != null) {
                this.inputFieldSKU.addEventListener('keyup', this.handleInputSKU.bind(this), false);
            }

            this.inputFieldSKU.addEventListener('keydown', (e:KeyboardEvent) => {
                const skuList = document.querySelector('.s-flyout__sku-list');
                const links = Array.from(skuList.querySelectorAll('a'));
                const active = links.find(x => x.classList.contains('active'));
                const index = links.indexOf(active);

                if (links.length !== 0) {
                    if(active) {
                        if (e.key === "ArrowDown" && (index +1 !== links.length)) {
                            links[index].classList.remove('active');
                            links[index + 1].classList.add('active');
                            links[index + 1].scrollIntoView(false);
                        } else if (e.key === "ArrowUp" && (index - 1 !== -1)) {
                            links[index].classList.remove('active');
                            links[index - 1].classList.add('active');
                            links[index - 1].scrollIntoView(false);
                        } else if (e.key === "Enter") {
                            active.click();
                        }
                    } else {
                        links[0].classList.add('active');
                    }
                }
            }, { passive: true });

            if (window.innerWidth < 992) {
                this.rearrangeStory(this.teaserPosition, document.querySelector('.s-flyout--search .s-flyout__teaser'), false);
            } else {
                this.rearrangeStory(0, document.querySelector('.s-flyout--search .s-flyout__teaser'), true);
            }
            window.addEventListener('resize', () => {
                if (window.innerWidth < 992) {
                    this.rearrangeStory(this.teaserPosition, document.querySelector('.s-flyout--search .s-flyout__teaser'), false);
                } else {
                    this.rearrangeStory(0, document.querySelector('.s-flyout--search .s-flyout__teaser'), true);
                }
            });
        }
    }

    rearrangeStory(position: Number, appendElement: any, origin: Boolean) {
        if (!origin) {
            const flyoutItem = document.querySelector('.s-flyout__search-col').childNodes;
            const row = document.createElement('div');
            row.classList.add('row');
            row.classList.add('s-flyout__teaser-row');
            row.appendChild(appendElement);

            flyoutItem.forEach((element, index) => {
                if (position == index) {
                    element.after(row);
                }
            });
        } else {
            const searchCol = document.querySelector('.s-flyout__search-col');
            const searchColParent = searchCol.parentElement;
            searchColParent.insertBefore(appendElement, searchColParent.firstChild);
        }
        this.deleteEmptyElements();
    }

    deleteEmptyElements() {
        const searchCol = document.querySelector('.s-flyout__search-col');
        searchCol.childNodes.forEach((element: any) => {
            if (element.innerHTML == '') {
                element.remove();
            }
        });
    }

    handleInput(event: Event) {
        if (this.inputField.value.length >= 3) {
            fetch(`/ajax/search/collection?q=${this.inputField.value}&source=${this.searchCollectionSource}&categories[]=${this.categories.join('&categories[]=')}&brand=${this.currentPageBrand}`, {method: "POST"})
            .then(response => response.json())
            .then(data => {
                this.handleRepsoneTags(data);
                /* check if the global id is not the same as the response id */
                if (this.defaultCollectionId != data["id"]) {
                    if (data["id"]) {
                        this.defaultCollectionId = data["id"];
                    }
                    this.handleResponse(data);
                }
            });
        } else {
            const tagDiv = document.querySelector('.s-flyout__tags') as HTMLElement;
            let defaultCollectionId = document.querySelector('[data-default]') as any;

            defaultCollectionId = defaultCollectionId.dataset.default;
            tagDiv.innerHTML = "";

            /* check if the default id is not the same as the global id */
            if (defaultCollectionId != this.defaultCollectionId) {
                this.defaultCollectionId = defaultCollectionId;
                this.defaultCollectionTeaser.innerHTML = this.defaultCollectionTeaserInnerHTML;
                this.defaultCollectionFirst.innerHTML = this.defaultCollectionFirstInnerHTML;
                this.defaultCollectionFirst.style.display = this.defaultCollectionFirstStyle;
                this.defaultCollectionFirst.classList.remove('active');
                this.defaultCollectionFirst.classList.add(this.defaultCollectionFirstClass);
                this.defaultCollectionSecond.innerHTML = this.defaultCollectionSecondInnerHTML;
                this.defaultCollectionSecond.style.display = this.defaultCollectionSecondStyle;
                this.defaultCollectionSecond.classList.remove('active');
                this.defaultCollectionSecond.classList.add(this.defaultCollectionSecondClass);
            }
        }
    }

    handleInputSKU(event: Event) {
        if((event as KeyboardEvent).key != 'ArrowDown' && (event as KeyboardEvent).key != 'ArrowUp' && (event as KeyboardEvent).key != 'Enter') {
            if (this.inputFieldSKU.value.length >= 3) {
                fetch(`/ajax/search/sku?sku=${this.inputFieldSKU.value}&brand=${this.currentPageBrand}`, {method: "POST"})
                .then(response => response.json())
                .then(data => this.handleResponseSKU(data));
            } else {
                const skuList = document.querySelector('.s-flyout__sku-list') as HTMLElement;
                skuList.setAttribute("style", "display: none;");
                skuList.innerHTML = "";
            }
        }
    }

    handleResponse(data: any) {
        /* teaser elements */
        const teaserHeadline = document.querySelector('.s-flyout--search .s-flyout__teaserheadline') as HTMLElement;
        const teaserContent = document.querySelector('.s-flyout--search .s-flyout__content') as HTMLElement;
        const teaserLink = document.querySelector('.s-flyout--search .s-flyout__teaserlink a') as HTMLAnchorElement;
        const teaserLinkIcon = document.querySelector('.s-flyout--search .s-flyout__teaserlink span') as HTMLAnchorElement;
        const teaserImage = document.querySelector('.s-flyout--search .s-flyout__image') as HTMLElement;

        /* first collection elements */
        const firstCollection = document.querySelectorAll('.s-flyout--search .s-flyout__search-snippet').item(0) as HTMLElement;
        const firstCollectionItems = document.querySelectorAll('.s-flyout--search .s-flyout__search-snippet-items .row').item(0);
        const firstCollectionTitle = document.querySelectorAll('.s-flyout--search .s-flyout__search-snippet-headline h5').item(0);
        const firstCollectionLink = document.querySelectorAll('.s-flyout--search .s-flyout__search-snippet-headline a').item(0) as HTMLAnchorElement;

        /* second collection elements */
        const secondCollection = document.querySelectorAll('.s-flyout--search .s-flyout__search-snippet').item(1) as HTMLElement;
        const secondCollectionItems = document.querySelectorAll('.s-flyout--search .s-flyout__search-snippet-items .row').item(1);
        const secondCollectionTitle = document.querySelectorAll('.s-flyout--search .s-flyout__search-snippet-headline h5').item(1);
        const secondCollectionLink = document.querySelectorAll('.s-flyout--search .s-flyout__search-snippet-headline a').item(1) as HTMLAnchorElement;

        if (data["id"]) {
            /* Teaser content */
            const textNode = document.createTextNode(data['story'].urlText);
            teaserHeadline.innerHTML = data['story'].title;
            teaserContent.innerHTML = data['story'].teaser;
            teaserLink.innerHTML = "";
            teaserLink.appendChild(teaserLinkIcon);
            teaserLink.appendChild(textNode);
            teaserLink.href = data['story'].url;
            teaserImage.style.backgroundImage = 'url('+data['story'].image+')';

            if (!!data['story'].urlText && !!data['story'].url) {
                document.querySelector('.s-flyout--search .s-flyout__teaserlink').classList.remove('d-none');
            } else {
                document.querySelector('.s-flyout--search .s-flyout__teaserlink').classList.add('d-none');
            }

            /* First collection content */
            if (data['firstCollection'].items != 0) {
                firstCollection.style.display = 'flex';
                firstCollectionTitle.innerHTML = data['firstCollection'].title;
                firstCollectionItems.innerHTML = '';

                if (data['secondCollection'].items == 0) {
                    firstCollection.classList.add('active');
                } else {
                    firstCollection.classList.remove('active');
                }

                if (data['firstCollection'].urlText) {
                    firstCollectionLink.href = data['firstCollection'].url ? data['firstCollection'].url : '';
                    firstCollectionLink.innerText = data['firstCollection'].urlText;
                    firstCollectionLink.style.display = 'inline-block';
                }

                for (let item of data['firstCollection'].items) {
                    const node = document.createElement('div');
                    node.classList.add('col');
                    if (item['type'] != 'artist') {
                        node.innerHTML = `<div class="s-flyout__snippet">
                                                <section class="s-product-nav-info e-rte">
                                                    <a href="${item['uri']}">
                                                        <div class="s-product-nav-info__image" style="background-image: url(${item['image']});">
                                                        </div>
                                                        <div class="s-product-nav-info__content">
                                                            ${item['title']}
                                                        </div>
                                                    </a>
                                                </section>
                                            </div>`;
                    } else {
                        node.innerHTML = `<div class="s-flyout__snippet">
                                                <div class="s-flyout-item">
                                                    <a href="${item['uri']}">
                                                        <div class="s-flyout-item__image" style="background-image: url(${item['image']})">
                                                        </div>
                                                        <div class="s-flyout-item__content">
                                                            <div class="s-flyout-item__gradient"></div>
                                                            <span class="s-flyout-item__headline">
                                                                ${item['title']}
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>`;
                    }

                    firstCollectionItems.appendChild(node);
                }
            } else {
                firstCollection.style.display = 'none';
            }

            /* Second collection content */
            if (data['secondCollection'].items != 0) {
                secondCollection.style.display = 'flex';
                secondCollectionTitle.innerHTML = data['secondCollection'].title;
                secondCollectionItems.innerHTML = '';

                if (data['secondCollection'].urlText) {
                    secondCollectionLink.href = data['secondCollection'].url ? data['secondCollection'].url : '';
                    secondCollectionLink.innerText = data['secondCollection'].urlText;
                    secondCollectionLink.style.display = 'inline-block';
                }

                if (data['firstCollection'].items == 0) {
                    secondCollection.classList.add('active');
                } else {
                    secondCollection.classList.remove('active');
                }

                for (let item of data['secondCollection'].items) {
                    const node = document.createElement('div');
                    node.classList.add('col');
                    if (item['type'] != 'artist') {
                        node.innerHTML = `<div class="s-flyout__snippet">
                                                <section class="s-product-nav-info e-rte">
                                                    <a href="${item['uri']}">
                                                        <div class="s-product-nav-info__image" style="background-image: url(${item['image']});">
                                                        </div>
                                                        <div class="s-product-nav-info__content">
                                                            ${item['title']}
                                                        </div>
                                                    </a>
                                                </section>
                                            </div>`;
                    } else {
                        node.innerHTML = `<div class="s-flyout__snippet">
                                                <div class="s-flyout-item">
                                                    <a href="${item['uri']}">
                                                        <div class="s-flyout-item__image" style="background-image: url(${item['image']})">
                                                        </div>
                                                        <div class="s-flyout-item__content">
                                                            <div class="s-flyout-item__gradient"></div>
                                                            <span class="s-flyout-item__headline">
                                                                ${item['title']}
                                                            </span>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>`;
                    }

                    secondCollectionItems.appendChild(node);
                }
            } else {
                secondCollection.style.display = 'none';
            }
        }
    }

    handleRepsoneTags(data: any) {
        /* tags */
        const tagDiv = document.querySelector('.s-flyout__tags') as HTMLElement;
        tagDiv.innerHTML = "";

        if (JSON.stringify(data['lucene']).length != 2) {
            for (let element of data['lucene']) {
                const span = document.createElement('span');
                const searchTerm = this.inputField.value.trim();

                if (searchTerm === element) {
                    continue;
                }

                if (element.indexOf(searchTerm) > -1) {
                    element = element.replace(searchTerm, '');
                    const bold = document.createElement('b');
                    const boldText = document.createTextNode(searchTerm);
                    const spanText = document.createTextNode(element);

                    bold.appendChild(boldText);
                    span.appendChild(bold);
                    span.appendChild(spanText);
                    tagDiv.appendChild(span);
                } else {
                    const spanText = document.createTextNode(element);
                    span.appendChild(spanText);
                    tagDiv.appendChild(span);
                }

                span.addEventListener('click', () => {
                    this.inputField.value = span.textContent;
                    this.inputField.form.submit();
                });
            };
        }
        if (data['elastic'].products && data['elastic'].products.length > 0) {
            for (let product of data['elastic'].products) {
                const span = document.createElement('span');
                const link = document.createElement('a');
                const icon = document.createElement('i');

                icon.classList.add(this.currentBrandIconClass);
                link.setAttribute('href', `/en/product-m${product['id']}.html`);
                link.appendChild(icon);
                link.appendChild(document.createTextNode(product['name']));
                span.appendChild(link);
                tagDiv.appendChild(span);
            }
        }
        if (data['elastic'].artists && data['elastic'].artists.length > 0) {
            for (let artist of data['elastic'].artists) {
                const span = document.createElement('span');
                const link = document.createElement('a');
                const icon = document.createElement('i');

                icon.classList.add('icon-icon-user');
                link.setAttribute('href', `/en/artist-a${artist['id']}.html`);
                link.appendChild(icon);
                link.appendChild(document.createTextNode(artist['name']));
                span.appendChild(link);
                tagDiv.appendChild(span);
            }
        }
    }

    handleResponseSKU(data: any) {
        const skuList = document.querySelector('.s-flyout__sku-list') as HTMLElement;
        const flyout = document.querySelector('.l-flyout--open') as HTMLElement;

        /* flyout height - 3x spacing - input field height*/
        const listHeight = flyout.offsetHeight - 108;

        skuList.setAttribute("style", "max-height:"+listHeight+"px; display: block;");
        skuList.innerHTML = "";

        if (JSON.stringify(data).length == 2) {
            skuList.innerHTML = "<span>NO RESULTS FOUND</span>";
        } else {
            for(let element in data) {
                const node = document.createElement('a');
                const textNode = document.createTextNode(data[element].sku);
                const url = window.location.origin;

                node.href = url + data[element].url;

                node.appendChild(textNode);
                skuList.appendChild(node);
            }
        }
    }
}