
// var $ = require('jquery');

export class Newsletter {

    constructor() {
        this.watchEvents();
    }

    protected watchEvents() {
        window.addEventListener('DOMContentLoaded', () => {

            if (localStorage.getItem("newsletterHide") === null && localStorage.getItem("newsletterClose") === null && localStorage.getItem("newsletterSuccess") != 'd-content' && localStorage.getItem("newsletterSuccess") != 'd-footer') {
                if (document.querySelectorAll('.l-header__subscribe')?.length) {
                    setTimeout(() => {
                        document.body.classList.add('js-l-header__subscribe--active');
                    }, 2000);
                }
            }

            document.querySelectorAll('.l-header__subscribe-close')?.forEach(close => {
                close.addEventListener('click', event => {
                    localStorage.setItem('newsletterClose', 'true');
                    document.body.classList.remove('js-l-header__subscribe--active');

                }, {passive: true});
            });

            document.querySelectorAll('.x-newsletter__form--subscribe')?.forEach(subscribe => {
                subscribe.addEventListener('submit', event => {
                    const submitID = subscribe.closest('.js-x-newsletter__id').id;
                    localStorage.setItem('newsletterSuccess', submitID);

                });
            });

            const successID = localStorage.getItem("newsletterSuccess");
            if (successID) {
                document.body.classList.add('js-newsletter__success', `js-newsletter__success--${successID}`);
                setTimeout(() => {
                    localStorage.setItem('newsletterHide', 'true');
                }, 2000);
            }

            if (localStorage.getItem("newsletterHide")) {
                document.body.classList.add('js-newsletter__hide');
                document.body.classList.remove(`js-newsletter__success`);
                ['d-header', 'd-content', 'd-footer'].forEach(state => {
                    document.body.classList.remove(`js-newsletter__success--${state}`);
                });
            }

            if (localStorage.getItem("newsletterClose")) {
                document.body.classList.remove('js-l-header__subscribe--active');
                document.body.classList.add('js-newsletter__hide--header');
            }

            if (document.querySelectorAll('.error-message')?.length) {
                localStorage.removeItem('newsletterSuccess');
            }
        });
    }
}