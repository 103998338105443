export class LocationSearchRequest {
  constructor() {}

  public async search(search: string): Promise<{status: number, data: Array<{zip: string, city: string, country: string}>}> {
    const requestBody = {search};
    const requestUrl = window.location.protocol + "//" + window.location.host + "/dealer_api/suggestions";
    const request = new Request(requestUrl, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    const response: {status: number, data: Array<{zip: string, city: string, country: string}>} = await fetch(request).then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error(response.statusText);
      }
    }).then(response => {
      return response;
    }).catch(error => {
      console.error(error);
    });

    return response;
  }
  public async getCoordinates(searchObject: {address: string, housenumber: string, zip: string, city: string}): Promise<{lat: number, lon: number}> {
    const requestBody = {...searchObject};
    const requestUrl = window.location.protocol + "//" + window.location.host + "/dealer_api/coordinates";
    const request = new Request(requestUrl, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    const response: {lat: number, lon: number} = await fetch(request).then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error(response.statusText);
      }
    }).then(response => {
      return response;
    }).catch(error => {
      console.error(error);
    });

    return response;
  }
}
