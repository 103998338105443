export class Jobs {
    private jobsForm: HTMLFormElement = document.querySelector('form[name="eebundles_jobsbundle_application_form"]');
    constructor() {
        if (this.jobsForm) {
            this.handleForm(this.jobsForm);
        }
    }
    private handleForm(form: HTMLFormElement) {
        const privacyCheckbox: HTMLInputElement = form.querySelector('#eebundles_jobsbundle_application_form_privacy_0');
        if (privacyCheckbox) {
            privacyCheckbox.required = true;
        }

        const fileInput: HTMLInputElement = form.querySelector('#eebundles_jobsbundle_application_form_files');
        const fileLabel: HTMLElement = fileInput?.parentElement?.querySelector('.custom-file-label');
        fileInput?.addEventListener('change', event => {
            let fileString = '';

            for(let i = 0; i < fileInput.files.length; i++) {
                if (fileString == '') {
                    fileString = `<span>${fileInput.files[i].name}</span>`;
                } else {
                    fileString += `<span>, ${fileInput.files[i].name}</span>`;
                }
            }

            if (fileLabel) {
                fileLabel.innerHTML = fileString;
            }
        })
    }
}