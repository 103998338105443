
export class Dealer {

    constructor() {
        this.watchEvents();
    }

    protected watchEvents() {
        document.addEventListener('DOMContentLoaded', event => {

            document.querySelectorAll('.x-dealer__info, .x-dealer__name')?.forEach(info => {
                info.addEventListener('click', event => {
                    const parentItem = info.closest('.x-dealer__item');
                    const isOpen = parentItem.classList.contains('x-dealer__item--open');

                    document.querySelectorAll('.x-dealer__item')?.forEach(item => {
                        item.classList.remove('x-dealer__item--open');
                    });
            
                    if (!isOpen) {
                        parentItem.classList.add('x-dealer__item--open');
                    }
                },{passive: true});
            });

            document.querySelector('.x-dealer__nav')?.addEventListener('click', function() {
                const target = document.querySelector('#dealer-search');
                const offsetTop = target.getBoundingClientRect().top + window.pageYOffset - 96;
            
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                });
            }, {passive: true});

        }, {passive: true});
    }
}