/**
 * pagetree-main.ts
 * Shows main pagetree with slide animation
 */

export class PagetreeMain {
  /**
   * Constructor
   */
  constructor() {
    this.togglePagetreeMain();
  }

  /**
   * Function togglePagetreeMain(): void
   * Watch click on opener and toggle class
   */
  protected togglePagetreeMain(): void {
    const pagetree = document.querySelector('.js-l-pagetree-main__opener') as HTMLElement;
    const header = document.querySelector('.js-l-header') as HTMLElement;
    const headerNav = document.querySelector('.l-header__nav') as HTMLElement;

    pagetree?.addEventListener('click',(event: Event) => {
        if (header.classList.contains('l-header__design--rootSite')) {
          headerNav.classList.toggle('l-header__nav--open');
          pagetree.classList.toggle('l-pagetree-main__opener--open');
        }
      }, { passive: true });
  }
}
