/**
 * brandswitcher.ts
 * Logic of pagetree navigation in panel
 */

import { Panel } from "../page/panel/panel";

export class Brandswitcher {
    protected panel: Panel;

    constructor(panel: Panel) {
        this.panel = panel;
        this.watchEvents();
    }

    /**
     * Function watchEvents():void
     * Watches all events of the brandswitch class
     **/
    public watchEvents(): void {
        const openerElements = document.querySelectorAll('.js-panel-brandswitcher__opener');
        const closeElements = document.querySelectorAll('.js-panel-brandswitcher__close, .js-l-panel__close');

        openerElements?.forEach(opener => {
            opener.addEventListener('click', (e) => {
                e.preventDefault();

                this.panel.closePanel();

                setTimeout(() => {
                    document.querySelector('.js-l-panel-nav')?.classList.add('hidden');
                }, 300);

                setTimeout(() => {
                    document.querySelector('.js-l-panel-brandswitcher')?.classList.remove('hidden');
                }, 300);

                setTimeout(() => {
                    this.panel.openPanel();
                }, 300);
            });
        });

        closeElements?.forEach(close => {
            close.addEventListener('click', (e) => {
                e.preventDefault();
                this.panel.closePanel();
                setTimeout(() => {
                    document.querySelector('.js-l-panel-brandswitcher')?.classList.add('hidden');
                }, 300);
            });
        });
    }
}
