export class Panel {

    /**
     * Initalise class variables
    **/
    protected scrollbarWidth: number;
    protected panelClass: string = 'l-panel';
    protected panelClassJS: string = 'js-l-panel';
    protected panelOpenAdditive: string = 'open';
    protected panelBackdropClass: string = '__backdrop';

    protected panelElement: HTMLElement | null = document.querySelector('.' + this.panelClassJS);
    protected panelOpenClass: string = this.panelClass + '--' + this.panelOpenAdditive;
    protected panelBackdropElement: HTMLElement | null = document.querySelector('.' + this.panelClassJS + this.panelBackdropClass);

    protected headerWidth: number = 1600;

    /**
     * Constructor
    **/
    constructor() {
        this.measureScrollbar();
        this.watchEvents();
    }

    /*
    * Function measureScrollbar():void
    * Measures scrollbar width of browser and adds a div 
    * that prevents jumping of content
    */
    measureScrollbar(): void {
        const scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);
        this.scrollbarWidth = window.innerWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
    }

    /*
    * Function openPanel():void
    */
    openPanel(): void {
        if (this.panelElement) {
            this.panelElement.classList.add(this.panelOpenClass);
        }

        const bodyElement: HTMLElement = document.querySelector('.js-l-body');
        const headerWrapper: HTMLElement = document.querySelector('.l-header__wrapper');
        if (bodyElement) {
            bodyElement.classList.add(this.panelOpenClass);
            bodyElement.style.marginRight = `${this.scrollbarWidth}px`;

            if (window.innerWidth < this.headerWidth && headerWrapper) {
                headerWrapper.style.paddingRight = `${this.scrollbarWidth}px`;
            }
        }

        const subscribeActive = document.querySelector('.js-l-header__subscribe--active');
        if (subscribeActive) {
            bodyElement?.classList.remove('js-l-header__subscribe--active');
        }
    }

    /*
    * Function closePanel():void
    */
    closePanel(): void {
        if (this.panelElement) {
            this.panelElement.classList.remove(this.panelOpenClass);
        }

        const bodyElement: HTMLElement = document.querySelector('.js-l-body');
        const headerWrapper: HTMLElement = document.querySelector('.l-header__wrapper');
        if (bodyElement) {
            bodyElement.classList.remove(this.panelOpenClass);
            bodyElement.style.marginRight = '0';
        }

        if (headerWrapper) {
            headerWrapper.style.paddingRight = '0';
        }
    }

    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
    **/
    watchEvents(): void {
        // Toggle open-class for panel on backdrop click
        if (this.panelBackdropElement) {
            this.panelBackdropElement.addEventListener('click', (e) => {
                e.preventDefault();
                this.closePanel();
            });

            this.panelBackdropElement.addEventListener('touchend', (e) => {
                e.preventDefault();
                this.closePanel();
            });
        }

        // Watch resizing of window to reset padding of header
        window.addEventListener('resize', () => {
            const bodyElement: HTMLElement = document.querySelector('.js-l-body');
            const headerWrapper: HTMLElement = document.querySelector('.l-header__wrapper');

            if (window.innerWidth < this.headerWidth && bodyElement?.classList.contains(this.panelOpenClass)) {
                if (headerWrapper) {
                    headerWrapper.style.paddingRight = `${this.scrollbarWidth}px`;
                }
            } else if (headerWrapper) {
                headerWrapper.style.paddingRight = '0';
            }

            if (window.innerWidth > 768) {
                this.closePanel();
            }
        });
    }
}
