/**
 * infinity-loader.ts
 **/

export class InfinityLoader {
  private wrapper: HTMLElement;
  private loaderEl: HTMLElement;
  private pattern: string;
  private target: string;
  private inProgress: boolean;
  private isDone: boolean;
  private initTS: number;
  private fps: number;

  constructor(selector?: any) {
    this.inProgress = false;
    this.isDone = false;
    this.initTS = performance.now();
    this.fps = 84;

    switch (typeof selector) {
      case 'undefined':
        selector = '.js-infinity-loader-wrapper';
      case 'string':
        document.querySelectorAll(selector)?.forEach((element) => {
          new InfinityLoader(element);
        });
        break;
      case 'object':
        this.init(selector);
        break;
    }
  }

  public spy(): void {
    const now = performance.now();
    const delta = now - this.initTS;

    if (delta > this.fps && !this.inProgress && this.wrapper.offsetParent !== null) {
      this.inProgress = true;

      if (this.loaderEl.getBoundingClientRect().top - window.innerHeight < -200) {
        if (this.loaderEl.classList.contains('ready')) {
          this.infinityLoader();
        }
      }
    }

    if (!this.isDone) {
      window.requestAnimationFrame(this.spy.bind(this));
    }

    this.initTS = now - (delta % this.fps);
  }

  /**
   * Execute loader when the trigger element (.loader) becomes visible.
   * Loads one page forward.
   */
  public infinityLoader(): void {
    this.loaderEl.classList.remove('ready');
    this.loaderEl.classList.add('active');
    const page = parseInt(this.loaderEl.dataset.page, 10) + 1;

    this.getPage(page, (html: string) => {
      this.isDone = true;
      this.loaderEl.insertAdjacentHTML('beforebegin', html);
      this.loaderEl.classList.remove('active');

      const pages: NodeListOf<HTMLElement> = this.wrapper.querySelectorAll(this.wrapper.dataset.child);
      const last = parseInt(pages[pages.length - 1].dataset.last, 10);
      const total = parseInt(pages[pages.length - 1].dataset.total, 10);

      if (!this.wrapper.querySelector(`${this.wrapper.dataset.child}.last`)) {
        this.loaderEl.classList.add('ready');
        this.loaderEl.dataset.page = page.toString();

        if (!isNaN(last) && !isNaN(total)) {
          const loadMoreEl = this.loaderEl.querySelector('#js-load-more-count') as HTMLElement;
          const loadMoreBar = document.querySelector<HTMLElement>('#js-load-more-count-bar');

          if (loadMoreBar) {
            loadMoreBar.style.width = `${(last / total) * 100}%`;
          }

          loadMoreEl.innerHTML = `${last} of ${total} results`;
          loadMoreEl.classList.remove('d-none');
        } else {
          const loadMoreCount = this.loaderEl.querySelector('#js-load-more-count') as HTMLElement;
          if (loadMoreCount) {
            loadMoreCount.classList.add('d-none');
          }
          const loadMoreBar = document.querySelector<HTMLElement>('#js-load-more-count-bar');
          if (loadMoreBar) {
            loadMoreBar.style.width = '0';
          }
        }
      }
    });
  }

  /**
   * Retrieve a page via AJAX call.
   * @param page
   * @param handler
   */
  public getPage(page: number, handler: (html: string) => void): void {
    const xhr = new XMLHttpRequest();
    const payload = {
      from: window.location.href,
      target: this.target,
      vw: window.innerWidth,
    };

    xhr.withCredentials = true;
    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        handler(xhr.responseText);
      }
    });
    xhr.open('POST', this.getUrl(page));
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(payload));
  }

  private getUrl(page: number): string {
    return this.pattern.replace('{page}', page.toString());
  }

  /**
   * Initiate an instance of self. Will be aborted, if required any setting is not present
   * @param el
   */
  private init(el: HTMLElement): void {
    this.wrapper = el;
    this.target = '';

    if (this.wrapper.dataset.pattern) {
      this.pattern = this.wrapper.dataset.pattern;
    } else {
      return;
    }

    if (this.wrapper.dataset.target) {
      this.target = this.wrapper.dataset.target;
    }

    this.loaderEl = document.createElement('DIV');
    this.loaderEl.classList.add('e-rte', 'loader', 'ready');
    this.loaderEl.dataset.page = '0';
    this.wrapper.insertAdjacentElement('beforeend', this.loaderEl);

    // Add control elements, if more pages are to be loaded
    if (!this.wrapper.dataset.single) {
      this.loaderEl.innerHTML = `
                <div class="e-rte x-products-list__load-more">
                <div id="js-load-more-count-bar"></div>
                <span id="js-load-more-count" class="d-none"></span>
                <a class="e-button__icon js-load-more"><span class="icon-icon-more-products"></span>Show more</a>
                <p class="js-to-top"><span class="l-to-top__arrow"></span> back to top</p>
                </div>
            `;

      // Bind frontend actions
      const loadMoreButton = this.loaderEl.querySelector('.js-load-more') as HTMLElement;
      if (loadMoreButton) {
        loadMoreButton.addEventListener('click', () => this.infinityLoader());
      }

      const toTopButton = this.loaderEl.querySelector('.js-to-top') as HTMLElement;
      if (toTopButton) {
        toTopButton.addEventListener('click', (event) => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          event.preventDefault();
        });
      }

      // Initial load (first page)
      this.infinityLoader();
    } else {
      // Initiate a scroll spy to wait for visibility
      window.requestAnimationFrame(() => this.spy());
    }
  }
}
