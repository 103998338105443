/**
 * panel-menu.js
 * Panel menu with an accordion mode and current opening submenu
 */

export class PagetreeSub {
    /**
     * Constructor
     */
    constructor() {
        this.watchEvents();
    }

    /**
     * Function to toggle flyout navigation
     */
    toggleFlyoutNav() {
        const self = this;

        // Watch click event on opener
        document.querySelectorAll('.js-l-flyout-nav__opener')?.forEach(opener => {
            opener.addEventListener('click', () => {
                (document.querySelectorAll('.l-pagetree-sub__secondary') as NodeListOf<HTMLElement>)?.forEach(sub => sub.style.display = 'none');
                (document.querySelectorAll('.js-l-pagetree-sub__opener') as NodeListOf<HTMLElement>)?.forEach(item => item.classList.remove('l-pagetree-sub__item--open'));

                // Show active submenu
                self.activeNav();
            }, {passive: true});
        });
    }

    /**
     * Function to toggle navigation
     */
    toggleNav() {
        const self = this;

        document.querySelectorAll('.js-l-pagetree-sub__opener')?.forEach(opener => {
            opener.addEventListener('click', () => {
                const subNavID = opener.getAttribute('data-subnav-primary');

                document.querySelectorAll('.js-l-pagetree-sub__opener')?.forEach(item => item.classList.remove('l-pagetree-sub__item--open'));
                opener.classList.add('l-pagetree-sub__item--open');
                (document.querySelectorAll('.l-pagetree-sub__secondary') as NodeListOf<HTMLElement>)?.forEach(sub => sub.style.display = 'none');
                (document.querySelectorAll(`*[data-subnav-secondary="${subNavID}"]`) as NodeListOf<HTMLElement>)?.forEach(sub => sub.style.display = 'block');

                return false;
            }, {passive: true});
        });
    }

    /**
     * Function to activate the current submenu
     */
    activeNav() {
        document.querySelectorAll('.js-l-pagetree-sub__opener')?.forEach(opener => {
            if (opener.classList.contains('l-pagetree-sub__item--active')) {
                const subNavID = opener.getAttribute('data-subnav-primary');
                (document.querySelectorAll(`*[data-subnav-secondary="${subNavID}"]`) as NodeListOf<HTMLElement>)?.forEach(sub => sub.style.display = 'block');
            }
        });
    }

    /**
     * Function to watch all events of the sub-navigation
     */
    watchEvents() {
        this.activeNav();
        this.toggleFlyoutNav();
        this.toggleNav();
    }
}
