/**
 * slider.ts
 * 
 * 
**/

var $ = require('jquery');
import 'slick-carousel';


export class Slider {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* SlickSlider initialize - add several classes */

		$('.js-slider__page').each(function() {
			$(self).on('init', function(event: any, slick: any){
				var modalID = $(".slick-current", self).data("target");
				$(self).append('<span class="c-slider__modal-open" data-toggle="modal" data-target="'+ modalID +'"></span>');
			});
		});


		/* Initalize slick slider */

		self.initSlickSlider();


		/* Watch events */

		self.watchEvents();
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the FlyoutNav class
	**/

	protected initSlickSlider():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		/* Initialize modals */

		$('.js-slider__modal').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			asNavFor: '.js-slider__page',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			customPaging : function(slider: any, i: any) {
				return '';
			}
		});


		/* Initialize slider */

		$(".js-slider__page").slick({
			dots: true,
			asNavFor: '.js-slider__modal',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			customPaging : function(slider: any, i: any) {
				return '';
			}
		});
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the FlyoutNav class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		/* Watch modal show */

		$('.c-slider__modal').on('shown.bs.modal', function (e: any) {
			$('.js-slider__modal').slick('refresh');
			setTimeout(function(){
				$('.js-slider__modal').css("visibility", "visible");
			}, 200);
		});
	}
}