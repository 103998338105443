import ol from "openlayers";
import { LocationRequest } from "./location-request";

export class MapzDealerLocations {
  private loader: HTMLElement = document.querySelector(".js-dealer-map-loader");
  private mapzContainer: HTMLElement = document.querySelector(".js-dealer-map");

  private userMarker: ol.Feature = null;
  private layer: ol.layer.Vector = null;
  private markerArray: Array<ol.Feature> = new Array();

  private dealerFolderId: number = null;
  private markerImgPath: string = null;
  private markerActiveImgPath: string = null;
  private userMarkerImgPath: string = null;
  private defaultLogoImgPath: string = null;

  private dealerErrorString: string = null;

  private defaultRadius: number = 100;
  private currentRadius: number = 100;

  private dealerList: HTMLElement = document.getElementById("dealer-list");
  private dealerListOnline: HTMLElement = document.getElementById("dealer-list-online");
  private dealerTabs: HTMLElement = document.getElementById('dealer-tabs');
  private sortElement: HTMLElement = document.getElementById("dealer-sort");
  private shadowItem: HTMLElement = document.getElementById("shadow-item");
  private shadowItemOnline: HTMLElement = document.getElementById("shadow-products-list");
  private shadowError: HTMLElement = document.getElementById("error_dealer");

  private noLocalDealer: boolean = false;
  private currentUserLocation: {lat: number, long: number} = null;

  private categories: Array<number> = null;
  private vip: string = null;


  constructor(private $location: LocationRequest) {
    if (this.mapzContainer) {
      this.initMap(this.mapzContainer);
    }
  }

  /**
   * Initializes the found map element and sets event listeners to listen if a place has changed.
   * @param mapElement the html element of the map
   */
  private async initMap(mapElement: HTMLElement): Promise<void> {
    this.loader?.classList.remove("d-none");

    const map = new ol.Map({
      target: mapElement,
      logo: false,
      // controls: ol.control.defaults.defaults(),
      layers: [
        new ol.layer.Tile({
          source: new ol.source.XYZ({
            attributions: [
              '© 2023 <a target="_blank" href="http://www.mapz.com">mapz.com </a>\
                - Map Data: <a target="_blank" href="http://openstreetmap.org">OpenStreetMap</a>\
                  (<a href="http://opendatacommons.org/licenses/odbl/1.0/" target="_blank">ODbL</a>)',
            ],
            tilePixelRatio: 2,
            url: "https://tiles.mapz.com/mapproxy/v1/meinl-2dc080a5/tiles/1.0.0/mapz_shades_of_gray_hq/EPSG3857/{z}/{x}/{-y}.jpeg",
          }),
        }),
      ],
      view: new ol.View({
        center: ol.proj.transform(
          [9.955445, 50.919104],
          "EPSG:4326",
          "EPSG:3857"
        ),
        zoom: 6,
      }),
    });

    map.updateSize();

    this.dealerFolderId = parseInt(mapElement.dataset.folder);
    this.markerImgPath = mapElement.dataset.marker;
    this.markerActiveImgPath = mapElement.dataset.markerActive;
    this.userMarkerImgPath = mapElement.dataset.userMarker;
    this.defaultLogoImgPath = mapElement.dataset.defaultLogo;
    this.currentUserLocation = {lat: 50.919104, long: 9.955445};

    this.loader?.classList.add("d-none");

    document.addEventListener('meinl:dealer-place-changed', async (event: Event) => {
      let customEvent = event as CustomEvent;
      this.loader?.classList.remove("d-none");
      this.currentUserLocation = {lat: customEvent.detail.lat, long: customEvent.detail.long};
      map.setView(new ol.View({
        center: ol.proj.transform(
          [this.currentUserLocation.long, this.currentUserLocation.lat],
          "EPSG:4326",
          "EPSG:3857"
        ),
        zoom: 6,
      }));

      const responseForMap = await this.$location.searchForLocations(this.currentUserLocation.lat, this.currentUserLocation.long, "map", this.currentRadius, this.dealerFolderId);
      const responseForOnline = await this.$location.searchForLocations(this.currentUserLocation.lat, this.currentUserLocation.long, "online", this.currentRadius, this.dealerFolderId);

      if (responseForMap) {
        this.renderDealerList(responseForMap, map);
        this.placeMarkerOnMap(map, responseForMap, this.currentUserLocation);
        map.updateSize();
      }

      if (responseForOnline) {
        this.renderOnlineList(responseForOnline, map);
      }
      this.loader?.classList.add("d-none");
    });

    document.addEventListener("meinl:dealer-filter-applied", async (event: Event) => {
      let customEvent = event as CustomEvent;
      this.loader?.classList.remove("d-none");
      const responseForMap = await this.$location.searchForLocations(this.currentUserLocation.lat, this.currentUserLocation.long, "map", this.currentRadius, this.dealerFolderId, customEvent.detail);
      const responseForOnline = await this.$location.searchForLocations(this.currentUserLocation.lat, this.currentUserLocation.long, "online", this.currentRadius, this.dealerFolderId, customEvent.detail);

      if (responseForMap) {
        this.renderDealerList(responseForMap, map);
        this.placeMarkerOnMap(map, responseForMap, this.currentUserLocation);
        map.updateSize();
      }

      if (responseForOnline) {
        this.renderOnlineList(responseForOnline, map);
      }
      this.loader?.classList.add("d-none");
    },
    { passive: true }
  );

  document.addEventListener("meinl:dealer-flagship-applied", async (event: Event) => {
    let customEvent = event as CustomEvent;
    this.loader?.classList.remove("d-none");
    this.vip = customEvent.detail[0]?.id;
    const responseForMap = await this.$location.searchForLocations(this.currentUserLocation.lat, this.currentUserLocation.long, "map", this.currentRadius, this.dealerFolderId, this.categories, this.vip);
    const responseForOnline = await this.$location.searchForLocations(this.currentUserLocation.lat, this.currentUserLocation.long, "online", this.currentRadius, this.dealerFolderId,  this.categories, this.vip);

    if (responseForMap) {
      this.renderDealerList(responseForMap, map);
      this.placeMarkerOnMap(map, responseForMap, this.currentUserLocation);
      map.updateSize();
    }

    if (responseForOnline) {
      this.renderOnlineList(responseForOnline, map);
    }
    this.loader?.classList.add("d-none");
  },
  { passive: true }
);

  }

  /**
   * Places all markers on the map
   * @param map the map element
   * @param mapResponse the response of the backend
   * @param userLocation the current user location
   */
  private placeMarkerOnMap(map: ol.Map, mapResponse: any, userLocation: { lat: number; long: number }): void {
    if (this.layer) {
      map.removeLayer(this.layer);
    }
    this.markerArray = new Array();
    this.userMarker = null;

    this.userMarker = new ol.Feature({
      geometry: new ol.geom.Point(
        ol.proj.transform(
          [userLocation.long, userLocation.lat],
          "EPSG:4326",
          "EPSG:3857"
        )
      ),
    });

    this.userMarker.setStyle(
      new ol.style.Style({
        image: new ol.style.Icon({
          src: this.userMarkerImgPath,
          imgSize: [168, 168],
          scale: 0.15,
        }),
        zIndex: 999999
      })
    );

    let count = 1;
    const mapsMarker = mapResponse.sort((a: any, b: any) => (a.distance > b.distance) ? 1 : ((b.distance > a.distance) ? -1 : 0));
    mapsMarker?.forEach((response: any) => {
      const marker = new ol.Feature({
        geometry: new ol.geom.Point(
          ol.proj.transform(
            [response.longitude, response.latitude],
            "EPSG:4326",
            "EPSG:3857"
          )
        ),
      });

      marker.setStyle(
        new ol.style.Style({
          image: new ol.style.Icon({
            src: this.markerImgPath,
            imgSize: [168, 240],
            scale: 0.25,
          }),
          zIndex: count
        })
      );
      count++;

      this.markerArray.push(marker);
    });

    this.layer = new ol.layer.Vector({
      source: new ol.source.Vector({
        features: [this.userMarker, ...this.markerArray],
      }),
    })

    map.addLayer(this.layer);

    let zoom = 9;

    // Set the zoom level manually because mapz has no cool feature like google maps :(
    switch (this.currentRadius) {
      case 50:
        zoom = 10;
        break;
      case 100:
        zoom = 9;
        break;
      case 200:
        zoom = 7;
        break;
      default:
        zoom = 9;
        break;
    }

    map.setView(new ol.View({
      center: ol.proj.transform(
        [userLocation.long, userLocation.lat],
        "EPSG:4326",
        "EPSG:3857"
      ),
      zoom: zoom,
    }));
  }

  /**
   * Renders the dealer list element on the side of the map
   * @param mapResponse the response from the backend
   * @param map the map element
   */
  private renderDealerList(mapResponse: any, map: ol.Map): void {
    if (this.dealerList && this.sortElement && this.shadowItem && this.shadowError && this.dealerTabs) {
      this.dealerList.innerHTML = '';
      this.dealerList.appendChild(this.sortElement);
      const distanceElement: HTMLFormElement = this.dealerList.querySelector('#dealer-distance-select');
      this.listenOnRadiusChange(distanceElement, map);
      let dealerCount = 0;
      const mapsMarker = mapResponse.sort((a: any, b: any) => (a.distance > b.distance) ? 1 : ((b.distance > a.distance) ? -1 : 0));

      // Renders all responses as items in the dealer list
      mapsMarker?.forEach((location: any) => {
        dealerCount++;

        const clonedNode = this.shadowItem.cloneNode(true);
        const clonedElement: HTMLElement = clonedNode as HTMLElement;

        const typ: HTMLElement = clonedElement.querySelector('.x-dealer__info-typ');
        const distance: HTMLElement = clonedElement.querySelector('.x-dealer__info-distance');
        const title: HTMLElement = clonedElement.querySelector('.x-dealer__name-title');
        const address: HTMLElement = clonedElement.querySelector('.x-dealer__name-details');
        const phone: HTMLElement = clonedElement.querySelector('.x-dealer__details-phone');
        const storeLink: HTMLLinkElement = clonedElement.querySelector('.x-dealer__details-store-link');
        const mapsLink: HTMLLinkElement = clonedElement.querySelector('.x-dealer__details-maps-link');
        const routeLink: HTMLLinkElement = clonedElement.querySelector('.x-dealer__details-route-link');
        const categoriesList: HTMLElement = clonedElement.querySelector('.x-dealer__categories-list');
        const storeIcon: HTMLElement = clonedElement.querySelector('.x-dealer__details-store');

        clonedElement.id = location.id;

        if (typ && location.type?.name) {
          typ.classList.add('vip');
          typ.innerHTML = location.type.name;
        } else if (typ) {
          typ.innerHTML = '';
        }

        if (distance) {
          distance.innerHTML = `${Math.round(location.distance)} km`;
        }

        if (title) {
          title.innerHTML = location.title;
        }

        if (address && (location.address && (location.zip || location.city))) {
          address.innerHTML = `${location.address} ${location.housenumber}, ${location.zip} ${location.city}`;
        }

        if (phone && location.phone) {
          phone.innerHTML = location.phone;
        }

        if (storeLink && location.viewType == 'both')  {
          storeLink.innerHTML = 'Online Store';
          storeLink.href = location.website;
        } else if (storeLink && location.viewType == 'local' && location.website != '') {
          storeLink.innerHTML = 'Website';
          storeLink.href = location.website;
        } else if (storeIcon) {
          storeIcon.parentNode.removeChild(storeIcon);
        }

        if (mapsLink) {
          mapsLink.innerHTML = 'Show on Google Maps';
          mapsLink.href = `https://www.google.com/maps/search/${location.title}+${location.address}+${location.housenumber}+${location.zip}+${location.city}`;
        }

        if (routeLink) {
          routeLink.innerHTML = 'Plan route';
          routeLink.href = `https://www.google.com/maps/dir/?api=1&destination=${location.title}+${location.address}+${location.housenumber}+${location.zip}+${location.city}`
        }

        if (categoriesList) {
          let count = 0;
          let categoriesString = '';

          location.categories?.forEach((category: { name: string }) => {
            if (count == 5) {
              categoriesString += `<span class="x-dealer__categories--more">+${location.categories.length - 5}</span><li class="x-dealer__categories--hide">${category.name}</li>`;
            } else if (count > 5) {
              categoriesString += `<li class="x-dealer__categories--hide">${category.name}</li>`;
            } else {
              categoriesString += `<li>${category.name}</li>`;
            }

            count++;
          });

          categoriesList.innerHTML = categoriesString;
        }
        clonedElement.style.display = 'block';
        this.dealerList.appendChild(clonedElement);
      });

      // Show Error, when no dealer is found
      if (!this.dealerErrorString) {
        this.dealerErrorString = this.shadowError.innerText;
      }

      let errorText = this.dealerErrorString;

      if (window.localStorage.getItem("flagship")) {
        errorText = errorText.replace("[[type]]", "flagship-store");
      } else {
          errorText = errorText.replace("[[type]]", "dealer");
      }

      if (distanceElement) {
        const distance = distanceElement.value;
        errorText = errorText.replace("[[radius]]", distance) + 'km';
      }

      this.shadowError.innerText = errorText;

      const dealerTab: HTMLElement = document.getElementById("local-dealer-tab");

      if (dealerCount == 0) {
        const clonedErrorNode = this.shadowError.cloneNode(true);
        (clonedErrorNode as HTMLElement).style.display = 'block';
        (clonedErrorNode as HTMLElement).style.padding = '32px';
        this.noLocalDealer = true;
      } else {
        this.noLocalDealer = false;
        dealerTab.click();
      }


      if (this.dealerList.innerHTML != '') {
        const items: NodeListOf<HTMLElement> = document.querySelectorAll('.x-dealer__item');
        items?.forEach(item => {
          item.addEventListener('click', () => {
            if (item.classList.contains('x-dealer__item--open')) {
              item.classList.remove("x-dealer__item--open");
            } else {
              const openItems: NodeListOf<HTMLElement> = document.querySelectorAll('.x-dealer__item.x-dealer__item--open');
              openItems?.forEach(open => {
                open.classList.remove("x-dealer__item--open");
              });
              item.classList.add("x-dealer__item--open");
            }
          }, {passive: true});
        });

        dealerTab.innerHTML = 'Local<span class="sm_invis"> Stores</span>';
        this.dealerTabs.style.display = 'flex';
        this.dealerList.classList.remove("init__list");
        this.mapzContainer.classList.remove("init__map");
      }
    }
  }

  private linkMarkerToDealer(map: ol.Map): void {
    const dealerItems: NodeListOf<HTMLElement> = document.querySelectorAll('.x-dealer__item');
    dealerItems?.forEach((item, index) => {
      item.addEventListener('mouseenter', event => {
        this.markerArray?.forEach(marker => {
          marker.setStyle(
            new ol.style.Style({
              image: new ol.style.Icon({
                src: this.markerImgPath,
                imgSize: [168, 240],
                scale: 0.25,
              }),
              zIndex: index
            })
          );
        });
        this.markerArray[index-1]?.setStyle(
          new ol.style.Style({
            image: new ol.style.Icon({
              src: this.markerActiveImgPath,
              imgSize: [168, 240],
              scale: 0.25,
            }),
            zIndex: index + 999999 + 1
          })
        );
      }, {passive: true});

      item.addEventListener('mouseleave', event => {
        this.markerArray?.forEach(marker => {
          marker.setStyle(
            new ol.style.Style({
              image: new ol.style.Icon({
                src: this.markerImgPath,
                imgSize: [168, 240],
                scale: 0.25,
              }),
              zIndex: index
            })
          );
        });
        if (item.classList.contains('x-dealer__item--open')) {
          this.markerArray[index-1]?.setStyle(
            new ol.style.Style({
              image: new ol.style.Icon({
                src: this.markerActiveImgPath,
                imgSize: [168, 240],
                scale: 0.28,
              }),
              zIndex: index + 999999 + 1
            })
          );
        }
      }, {passive: true});

      item.addEventListener('click', event => {
        if (item.classList.contains('x-dealer__item--open')) {
          this.markerArray?.forEach(marker => {
            marker.setStyle(
              new ol.style.Style({
                image: new ol.style.Icon({
                  src: this.markerImgPath,
                  imgSize: [168, 240],
                  scale: 0.25,
                }),
                zIndex: index
              })
            );
          });
          const marker = this.markerArray[index]
          marker?.setStyle(
            new ol.style.Style({
              image: new ol.style.Icon({
                src: this.markerActiveImgPath,
                imgSize: [168, 240],
                scale: 0.28,
              }),
              zIndex: index + 999999 + 1
            })
          );
        } else {
          this.markerArray?.forEach(marker => {
            marker.setStyle(
              new ol.style.Style({
                image: new ol.style.Icon({
                  src: this.markerImgPath,
                  imgSize: [168, 240],
                  scale: 0.25,
                }),
                zIndex: index
              })
            );
          });
        }
      }, {passive: true});
    });

    map.on("click", (evt: any) => {
      this.markerArray?.forEach((marker, index) => {
        marker.setStyle(
          new ol.style.Style({
            image: new ol.style.Icon({
              src: this.markerImgPath,
              imgSize: [168, 240],
              scale: 0.25,
            }),
            zIndex: index
          })
        );
      });
      const feature = map.forEachFeatureAtPixel(evt.pixel, (feature: any, layer: any) => {return feature;});

      if (feature && feature.getGeometry()) {
        const foundIndex = this.markerArray.findIndex((x: any) => x.getGeometry()["A"] === feature.getGeometry()["A"]);

        if (foundIndex + 1) {
          this.markerArray[foundIndex].setStyle(
            new ol.style.Style({
              image: new ol.style.Icon({
                src: this.markerActiveImgPath,
                imgSize: [168, 240],
                scale: 0.28,
              }),
              zIndex: foundIndex + 999999 + 1
            })
          );
          dealerItems[foundIndex + 1]?.classList.add('x-dealer__item--open');
          dealerItems[foundIndex + 1]?.scrollIntoView(true);
        }
      } else {
        dealerItems?.forEach(item => {
          item.classList.remove('x-dealer__item--open');
        });
      }
    });
  }

  /**
   * Renders the online list of all dealers found
   * @param onlineResponse the response of the backend
   * @param map the map element
   */
  private renderOnlineList(onlineResponse: any, map: ol.Map): void {
    let dealerCount = 0;
    const distanceElement: HTMLFormElement = this.dealerList.querySelector('#dealer-distance-select');
    this.listenOnRadiusChange(distanceElement, map);
    this.dealerListOnline.innerHTML = '';

    if (this.dealerListOnline) {
      onlineResponse?.forEach((location: any) => {
        if (location.website != '') {
          dealerCount++;
          const clonedNode = this.shadowItemOnline.cloneNode(true);
          const clonedElement: HTMLElement = clonedNode as HTMLElement;

          const wrapperLink: HTMLLinkElement = clonedElement.querySelector('.x-products-list__item-wrapper-link');
          const logoImg: HTMLImageElement = clonedElement.querySelector('.x-products-list__item-logo');
          const itemLink: HTMLLinkElement = clonedElement.querySelector('.x-products-list__item-link');

          if (wrapperLink) {
            wrapperLink.href = location.website
          }

          if (logoImg && location.logo != undefined) {
            logoImg.src = location.logo;
          } else if(logoImg) {
            logoImg.src = this.defaultLogoImgPath;
            logoImg.classList.add("default__logo");
          }

          if (itemLink) {
            itemLink.href = location.website;
            itemLink.innerHTML = location.title;
            if (location.type?.name) {
              itemLink.classList.add('vip');
            }
          }
          clonedElement.style.display = 'block';
          this.dealerListOnline.appendChild(clonedElement);
        }
      });

      if (!this.dealerErrorString) {
        this.dealerErrorString = this.shadowError.innerText;
      }

      let errorText = this.dealerErrorString;

      if (window.localStorage.getItem("flagship")) {
        errorText = errorText.replace("[[type]]", "flagship-store");
      } else {
        errorText = errorText.replace("[[type]]", "dealer");
      }

      if (distanceElement) {
        const distance = distanceElement.value;
        errorText = errorText.replace("[[radius]]", distance) + 'km';
      }

      const dealerTab: HTMLElement = document.getElementById("local-dealer-tab");

      if (dealerCount == 0) {
        const clonedErrorNode = this.shadowError.cloneNode(true);
        (clonedErrorNode as HTMLElement).style.display = 'block';
        dealerTab?.click();
      }

      const onlineDealerTab: HTMLElement = document.getElementById("online-dealer-tab");
      if (this.noLocalDealer && dealerCount != 0) {
        onlineDealerTab?.click();
      }

      if (onlineDealerTab) {
        onlineDealerTab.innerHTML = 'Online<span class="sm_invis"> Stores</span>';
      }
    }
    this.linkMarkerToDealer(map);
  }

  /**
   * Creates an event listener if the radius changes
   * @param radiusElement the radius form element
   * @param map the map element
   */
  private listenOnRadiusChange(radiusElement: HTMLFormElement, map: ol.Map) {
    radiusElement?.addEventListener('change', async () => {
      const distance = parseInt(radiusElement.value);
      this.currentRadius = distance;
      this.loader?.classList.remove("d-none");
      const responseForMap = await this.$location.searchForLocations(this.currentUserLocation.lat, this.currentUserLocation.long, "map", this.currentRadius, this.dealerFolderId, this.categories, this.vip);
      const responseForOnline = await this.$location.searchForLocations(this.currentUserLocation.lat, this.currentUserLocation.long, "online", this.currentRadius, this.dealerFolderId, this.categories, this.vip);

      if (responseForMap) {
        this.renderDealerList(responseForMap, map);
        this.placeMarkerOnMap(map, responseForMap, this.currentUserLocation);
        map.updateSize();
      }

      if (responseForOnline) {
        this.renderOnlineList(responseForOnline, map);
      }
      this.loader?.classList.add("d-none");
    });
  }
}
