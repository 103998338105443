/**
 * panel-nav.ts
 * Logic of pagetree navigation in panel
 */
import { FlyoutNav } from '../nav/flyout-nav';
import { Panel } from '../page/panel/panel';
import { PagetreePanel } from './pagetree/pagetree-panel';

export class Panelnav {
  private panel: Panel;
  private flyout: FlyoutNav;

  constructor(panel: Panel, flyout: FlyoutNav, accordionMode: boolean = true, openCurrent: boolean = true, animationDuration: number = 1000, overviewpagesPossible: boolean = false) {
    this.panel = panel;
    this.flyout = flyout;

    // Call functions
    this.watchEvents();

    // Get instance of panelmenu
    new PagetreePanel(accordionMode, openCurrent, animationDuration, overviewpagesPossible);
  }

  /**
   * Function to watch all events of the navigation class
   */
  public watchEvents(): void {
    // Toggle navigation
    document.querySelectorAll('.js-l-pagetree-panel__opener')?.forEach((opener) => {
      opener.addEventListener('click', (e) => {
        e.preventDefault();
        const panelNav = document.querySelector('.js-l-panel-nav') as HTMLElement;
        if (panelNav) {
          panelNav.style.display = 'flex';
        }
        this.flyout.closeNav();
        setTimeout(() => {
          this.panel.openPanel();
        }, 50);
      });
    });

    document.querySelectorAll('.js-panel-nav__close, .js-l-panel__close')?.forEach((closeButton) => {
      closeButton.addEventListener('click', (e) => {
        e.preventDefault();
        this.panel.closePanel();
        setTimeout(() => {
          const panelNav = document.querySelector('.js-l-panel-nav') as HTMLElement;
          if (panelNav) {
            panelNav.style.display = 'none';
          }
        }, 300);
      });
    });
  }
}
