export class LocationRequest {
  constructor() {}

  public async searchForLocations(lat: number, lon: number, view: "map" | "online", distance: number, folder: number, categories: Array<number> = null, type: string = null) {
      const requestBody = {lat, lon, view, distance, folder, categories, type};
      const requestUrl = window.location.protocol + "//" + window.location.host + "/dealer_api/search";
      const request = new Request(requestUrl, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: new Headers({ "Content-Type": "application/json" }),
      });

      const response = await fetch(request).then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      }).then(response => {
        return response;
      }).catch(error => {
        console.error(error);
      });

      return response;
  }
}
