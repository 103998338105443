declare global {
    interface Window {
        compileFilter: any,
        serialize: any
    }
}

export class Products {
    constructor() {
        window.addEventListener('load', function() {
            let filterControls = document.querySelectorAll(
                '.x-products-list__filters input[type="radio"],' +
                '.x-products-list__filters input[type="checkbox"]'
            );
            for (let i = 0; i < filterControls.length; i++) {
                filterControls[i].addEventListener('change', function() {
                    $.ajax({
                        "url": "/en/ajax/products/count",
                        "method": "POST",
                        "dataType": "json",
                        "headers": {
                            "content-type": "application/json"
                        },
                        "data": JSON.stringify({
                            "from": window.location.origin + '?' + window.serialize(document.querySelector('#js_filterfield'))
                        }),
                    }).done(function (response: any) {
                        var total = parseInt(response.total, 10);
                        document.querySelector<HTMLFormElement>("#x-products-list__filter-count").innerHTML = response.total + ((total == 1) ? " item" : " items");
                        document.querySelector<HTMLFormElement>(".x-products-list__filter button").disabled = (total <= 0);
                    });
                }, false);
            }

            let sortControls = document.querySelectorAll('.x-products-list__sort select[name="orderBy"]');
            for (let i = 0; i < sortControls.length; i++) {
                sortControls[i].addEventListener('change', function (event: any) {
                    document.querySelector<HTMLInputElement>('.x-products-list__filters input[name="orderBy"]').value = event.target.value;
                    document.querySelector<HTMLFormElement>('#js_filterfield').submit();
                }, false)
            }

            let filterToggles = document.querySelectorAll('.js-x-products-list__filters-toggle');
            for (let i = 0; i < filterToggles.length; i++) {
                filterToggles[i].addEventListener('click', function() {
                    document.querySelector('.x-products-list').classList.toggle('x-products-list--no-filter');

                    filterToggles[i].classList.toggle('js-x-products-list__filters--show');
                    filterToggles[i].classList.toggle('js-x-products-list__filters--hide');
                }, false);
            }

            let groupToggles = document.querySelectorAll('.js-x-products-list__filters-group');
            for (let i = 0; i < groupToggles.length; i++) {
                groupToggles[i].addEventListener('click', function(event: any) {
                    let uid = event.target.dataset.uid;
                    if (typeof(uid) !== "undefined") {
                        let inputGroupChildren = document.querySelectorAll<HTMLFormElement>("#"+uid+" .child-input");
                        for (let j = 0; j < inputGroupChildren.length; ++j) {
                            inputGroupChildren[j].checked = event.target.checked;
                        }
                    }
                }, false);
            }
        }, false);
    }
}
window.compileFilter = function(element: HTMLElement) {
    switch (element.dataset.type) {
        case 'numeric':
        case 'string':
            document.querySelector<HTMLFormElement>('input[name="'+element.dataset.filter+'"][value=""]').checked = true;
        break;
        case 'multiple':
            document.querySelector<HTMLFormElement>('input[name="'+element.dataset.filter+'[]"][value="'+element.innerHTML.trim()+'"]').checked = false;
        break;
        case 'relation':
            document.querySelector<HTMLFormElement>('input[name="'+element.dataset.filter+'[]"][value="'+element.dataset.value.trim()+'"]').checked = false;
        break;
        case 'select':
        case 'category':
            document.querySelector<HTMLFormElement>('input[name="'+element.dataset.filter+'"][value="'+element.dataset.value.trim()+'"]').checked = false;
        break;
    }
    document.querySelector<HTMLFormElement>('#js_filterfield').submit();
}
window.serialize = function(form: any) {
    let arr:Array<String> = [];
    Array.prototype.slice.call(form.elements).forEach(function (field:HTMLFormElement) {
        if (!field.name || field.disabled || ['file', 'reset', 'submit', 'button'].indexOf(field.type) > -1) return;
        if (field.type === 'select-multiple') {
            Array.prototype.slice.call(field.options).forEach(function (option:HTMLFormElement) {
                if (!option.selected) return;
                arr.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(option.value));
            });
            return;
        }
        if (['checkbox', 'radio'].indexOf(field.type) >-1 && !field.checked) return;
        arr.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value));
    });
    return arr.join('&');
}