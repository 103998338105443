import 'js-cloudimage-360-view';

declare global {
    interface Window {
        CI360: any;
    }
}

export class Product360 {
    constructor() {
        this.toggleViewer();
    }

    toggleViewer() {
        const products360grad = document.querySelectorAll('.js-product-360') as NodeListOf<HTMLElement>;
        window.CI360.destroy();
        products360grad.forEach((product360grad)=>{
            const wrapper = product360grad.querySelector('.js-product-360__wrapper') as HTMLElement;
            const image = product360grad.querySelector('.js-product-360__thumbnail') as HTMLElement;
            const play = product360grad.querySelector('.js-product-360__play') as HTMLElement;
            const id = wrapper.id;
            

            if (image && image.offsetHeight) {
                image.parentElement.style.minHeight = image.offsetHeight + 'px';
            }

            play?.addEventListener('click', () => {
                // const id = wrapper.id;
                // window.CI360.init();
                window.CI360.add(id);
                // window.CI360.update(id);

                setTimeout(() => {
                    image.style.opacity = '0';
                    image.style.pointerEvents = 'none';
                    wrapper.style.opacity = '1';
                }, 500);
            });
        })
        
    }
}