import { LocationSearchRequest } from "./location-search-request";

export class LocationSearch {
  private searchInput: HTMLInputElement = document.getElementById('dealer-search-field') as HTMLInputElement;
  private geolocationButton: HTMLElement = document.getElementById('geolocation');


  constructor(private $search: LocationSearchRequest) {
    if (this.searchInput) {
      this.handleSearch(this.searchInput);
    }

    if (this.geolocationButton) {
      this.handleGeolocationButton(this.geolocationButton);
    }
  }

  private handleSearch(input: HTMLInputElement): void {
    const dropdown = document.querySelector('.x-dealer__search-dropdown');
    const dropdownWrapper = document.querySelector('.x-dealer__search-dropdown-wrapper');

    input.addEventListener('input', async () => {
      if (input.value.length > 1) {
        dropdown.classList.remove('d-none');
        const response = await this.$search.search(input.value);

        if (response.data) {
          dropdownWrapper.innerHTML = '';

          response.data.forEach((value: { zip: string; city: string; country: string; }) => {
            const dropdownItemValue = document.createElement('div');
            const parts = [];

            if (value.zip) {
              parts.push(value.zip);
            }

            if (value.city) {
              parts.push(value.city);
            }

            if (value.country) {
              parts.push(value.country);
            }

            dropdownItemValue.innerHTML = parts.join(', ');

            dropdownItemValue.addEventListener('click', () => {
              dropdown.classList.add('d-none');
              input.value = dropdownItemValue.innerHTML;

              const enterKeyEvent = new KeyboardEvent('keypress', { key: 'Enter' });
              input.dispatchEvent(enterKeyEvent);
            });

            dropdownWrapper.appendChild(dropdownItemValue);
          });
        }
      } else {
        dropdown.classList.add('d-none');
        dropdownWrapper.innerHTML = '';
      }
    });

    input.addEventListener('keypress', async (event) => {
      if (event.key === 'Enter') {
        dropdown.classList.add('d-none');
        event.preventDefault();

        const response = await this.$search.search(input.value);

        if (input.value.length > 1) {
          if (Number.isNaN(parseInt(input.value))) {
            const searchObject: {address: string, housenumber: string, zip: string, city: string} = {'address': null, 'housenumber': null, 'zip': null, 'city': input.value};
            const responseLatLong = await this.$search.getCoordinates(searchObject);
            if (responseLatLong.lat != null && responseLatLong.lon != null) {
              document.dispatchEvent(new CustomEvent('meinl:dealer-place-changed', {detail: {lat: responseLatLong.lat, long: responseLatLong.lon}}));
            }
          } else {
            const splittedInput = input.value.split(', ');
            const searchObject: {address: string, housenumber: string, zip: string, city: string} = {'address': null, 'housenumber': null, 'zip': splittedInput[0] ?? null, 'city': splittedInput[1] ?? null};
            const responseLatLong = await this.$search.getCoordinates(searchObject);
            if (responseLatLong.lat != null && responseLatLong.lon != null) {
              document.dispatchEvent(new CustomEvent('meinl:dealer-place-changed', { detail: { lat: responseLatLong.lat, long: responseLatLong.lon } }));
            }
          }
        }
      }
    });
  }

  private handleGeolocationButton(button: HTMLElement): void {
    button.addEventListener('click', event => {
      event.preventDefault();
      if (window.navigator && window.navigator.geolocation) {
        button.style.cursor = 'wait';
        window.navigator.geolocation.getCurrentPosition((position) => {
          document.dispatchEvent(new CustomEvent('meinl:dealer-place-changed', {detail: {lat: position.coords.latitude, long: position.coords.longitude}}));
          button.style.cursor = 'pointer';
        });
      } else {
        window.alert('Geolocation is not supported in this Browser');
      }
    })
  }
}
