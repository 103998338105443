/**
 * to-top.ts
 * Logic of to-top button on page end
 *
 */

export class Header {

    /**
     * Constructor
     **/
    constructor() {
        this.toggleSmallHeader();
        this.watchEvents();
    }

    /**
     * Function toggleSmallHeader
     **/
    protected toggleSmallHeader(): void {
        const documentHtml = document.documentElement;
        const documentBody = document.body;

        // Get scroll position
        const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

        if (scrollPos > 20) {
            if (!documentHtml.classList.contains('js-l-header--small')) {
                documentHtml.classList.add('js-l-header--small');
                documentBody.classList.remove('js-l-header__subscribe--active');
            }
        } else {
            if (documentHtml.classList.contains('js-l-header--small')) {
                documentHtml.classList.remove('js-l-header--small');
                // if (document.cookie.indexOf('newsletterClosedCookie=1') == -1 && document.cookie.indexOf('newsletterCookie=1') == -1) {
                //     documentBody.classList.add('js-l-header__subscribe--active');
                // }
            }
        }
    }

    protected setActivePage(): void {
        const htmlElement = document.querySelector('html') as HTMLElement;
        const pageID = htmlElement.dataset.pageId;
        const parentPageID = htmlElement.dataset.parentPageId;

        const navItems: NodeListOf<HTMLElement> = document.querySelectorAll('.l-header__nav li');
        navItems?.forEach((navItem: HTMLElement) => {
            const navPageID = navItem.dataset.mainnavMain;

            if (navPageID === pageID || navPageID === parentPageID) {
                navItem.classList.add('l-pagetree-main__item--active', 'active');
            }
        });
    }

    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
     **/
    protected watchEvents(): void {
        // Toggle HTML class if page is scrolled
        window.addEventListener('scroll', () => {
            this.toggleSmallHeader();
        });

        this.setActivePage();
    }
}
