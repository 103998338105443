import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// @ts-ignore
import Slick from 'slick-carousel';
import Bootstrap from 'bootstrap';

export class ProductDetail {
  constructor() {
    // Initialize any properties or methods if needed
  }
}

function toggleFixedProductInfo() {
  const documentHtml: HTMLElement | null = document.querySelector('html');
  const documentHeight = (window.innerHeight / 4) * 3;

  const scrollPos = window.pageYOffset || window.scrollY || document.body.scrollTop;

  if (scrollPos > documentHeight) {
    documentHtml?.classList.add('js-x-products--fixed');
  } else {
    documentHtml?.classList.remove('js-x-products--fixed');
  }

  const icons: NodeListOf<HTMLElement> = document.querySelectorAll('.x-products-intro__icons');
  if (window.scrollY > 100) {
    icons?.forEach((icon) => icon.classList.add('x-products-intro__icons--active'));
  } else {
    icons?.forEach((icon) => icon.classList.remove('x-products-intro__icons--active'));
  }
}

function initSlickSlider(selector: string, options: any) {
  const element = document.querySelector(selector) as HTMLElement;
  if (element) {
    new Slick(element, options);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  let firstTime = true;

  initSlickSlider('.js-x-products-intro__slider-images', {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    infinite: false,
    draggable: false,
    asNavFor: '.js-x-products-intro__thumbnails-images,.js-x-products-intro__modal-images',
    prevArrow: '<span class="slick-prev"></span>',
    nextArrow: '<span class="slick-next"></span>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          fade: false,
          arrows: true,
        },
      },
    ],
  });

  setTimeout(() => {
    const sliderItemContainer = document.querySelector('.js-x-products-intro__slider-images') as HTMLElement;
    const sliderItems = sliderItemContainer?.querySelectorAll('.slick-slide');
    let image = false;

    sliderItems?.forEach((item, index) => {
      if (!image) {
        const realImage = item.querySelector('.x-products-intro__slider-images-toggle') as HTMLElement;
        if (realImage) {
          image = true;
          (sliderItemContainer as any).slick('slickGoTo', index);
          return;
        }
      }
    });
  }, 200);

  const thumbnailContainer = document.querySelector('.js-x-products-thumbnails') as HTMLElement;
  const thumbnails = thumbnailContainer?.querySelectorAll('.x-products-intro__thumbnail');

  const updateThumbnails = (currentSlide: number) => {
    const active = thumbnailContainer.querySelector('.x-products-intro__thumbnail.active') as HTMLElement;
    active?.classList.remove('active');
    thumbnails[currentSlide]?.classList.add('active');
  };

  const sliderElement = document.querySelector('.js-x-products-intro__slider-images') as Slick;
  sliderElement?.addEventListener('afterChange', (event: any, slick: any, currentSlide: any) => {
    updateThumbnails(currentSlide);
  });

  thumbnails.forEach((thumbnail, index) => {
    thumbnail.addEventListener(
      'click',
      () => {
        (sliderElement as any).slick('slickGoTo', index);
      },
      { passive: true }
    );
  });

  const openThumbnailsButton = thumbnailContainer?.querySelector('.js-x-products-thumbnail-open') as HTMLElement;

  openThumbnailsButton?.addEventListener('click', () => {
    const openerElement = thumbnailContainer?.querySelector('.js-x-products-thumbnail-opener') as HTMLElement;
    if (openerElement?.classList.contains('open')) {
      openerElement.classList.remove('open');
      setTimeout(() => {
        openerElement.classList.add('d-none');
        openThumbnailsButton.classList.remove('open');
      }, 500);
    } else {
      openerElement.classList.remove('d-none');
      setTimeout(() => {
        openerElement.classList.add('open');
        openThumbnailsButton.classList.add('open');
      }, 100);
    }
  });

  // Initialize other sliders
  initSlickSlider('.js-x-products-intro__modal-images', {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    focusOnSelect: true,
    fade: false,
    infinite: false,
    draggable: false,
    asNavFor: '.js-x-products-intro__slider-images',
    prevArrow: '<span class="slick-prev"></span>',
    nextArrow: '<span class="slick-next"></span>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          fade: false,
          arrows: true,
        },
      },
    ],
  });

  initSlickSlider('.js-x-products-intro__slider-lifestyle-images', {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    infinite: false,
    asNavFor: '.js-x-products-intro__thumbnails-lifestyle-images,.js-x-products-intro__modal-lifestyle-images',
    prevArrow: '<span class="slick-prev"></span>',
    nextArrow: '<span class="slick-next"></span>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          fade: false,
          arrows: false,
        },
      },
    ],
  });

  initSlickSlider('.js-x-products-intro__thumbnails-lifestyle-images', {
    slidesToShow: 6,
    slidesToScroll: 6,
    asNavFor: '.js-x-products-intro__slider-lifestyle-images,.js-x-products-intro__modal-lifestyle-images',
    arrows: false,
    infinite: false,
    focusOnSelect: true,
    draggable: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          draggable: true,
        },
      },
    ],
  });

  initSlickSlider('.js-x-products-intro__modal-lifestyle-images', {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    focusOnSelect: true,
    fade: false,
    infinite: false,
    asNavFor: '.js-x-products-intro__slider-lifestyle-images,.js-x-products-intro__thumbnails-lifestyle-images',
    prevArrow: '<span class="slick-prev"></span>',
    nextArrow: '<span class="slick-next"></span>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          fade: false,
          arrows: false,
        },
      },
    ],
  });

  initSlickSlider('.js-x-products-intro__slider-360deg-viewer', {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    infinite: false,
    draggable: false,
    asNavFor: '.js-x-products-intro__thumbnails-360deg-viewer',
    prevArrow: '<span class="slick-prev"></span>',
    nextArrow: '<span class="slick-next"></span>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          fade: false,
          arrows: false,
        },
      },
    ],
  });

  initSlickSlider('.js-x-products-intro__thumbnails-360deg-viewer', {
    slidesToShow: 4,
    slidesToScroll: 4,
    asNavFor: '.js-x-products-intro__slider-360deg-viewer',
    focusOnSelect: true,
    arrows: false,
    infinite: false,
  });

  initSlickSlider('.js-x-products-intro__slider-videos', {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    infinite: false,
    draggable: false,
    asNavFor: '.js-x-products-intro__thumbnails-videos',
    prevArrow: '<span class="slick-prev"></span>',
    nextArrow: '<span class="slick-next"></span>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          fade: false,
          arrows: false,
        },
      },
    ],
  });

  initSlickSlider('.js-x-products-intro__thumbnails-videos', {
    slidesToShow: 5,
    slidesToScroll: 5,
    asNavFor: '.js-x-products-intro__slider-videos',
    focusOnSelect: true,
    arrows: false,
    infinite: false,
  });

  toggleFixedProductInfo();

  window.addEventListener('scroll', toggleFixedProductInfo);

  const optionsLinks = document.querySelectorAll('.x-products-intro__options-more-link');
  optionsLinks?.forEach((link) => {
    link.addEventListener(
      'click',
      () => {
        link.innerHTML = link.classList.contains('collapsed') ? 'Show less' : 'Show more';
      },
      { passive: true }
    );
  });

  const moreLinks = document.querySelectorAll('.x-products-intro__variants-more-link');
  moreLinks?.forEach((link) => {
    link.addEventListener(
      'click',
      () => {
        link.innerHTML = link.classList.contains('collapsed') ? 'Show less' : 'Show more';
      },
      { passive: true }
    );
  });

  const toggleModal = (selector: string) => {
    const element = document.querySelector(selector) as HTMLElement;
    if (element) {
      if (window.innerWidth >= 768) {
        const modal = new Bootstrap.Modal(element, { backdrop: false });
        modal.show();
      }
    }
  };

  document.querySelectorAll('.x-products-intro__slider-images-toggle').forEach((button) => {
    button.addEventListener('click', () => toggleModal('#x-products-intro__slider-images'));
  });

  document.querySelectorAll('.x-products-intro__slider-lifestyle-images-toggle').forEach((button) => {
    button.addEventListener('click', () => toggleModal('#x-products-intro__slider-lifestyle-images'));
  });

  // Handle closing modals on outside click
  const handleOutsideClick = (modalSelector: string) => {
    const modal = document.querySelector(modalSelector) as HTMLElement;
    if (modal) {
      modal.addEventListener('shown.bs.modal', () => {
        const handleClickOutside = (event: MouseEvent) => {
          if (!(event.target as HTMLElement).closest('.x-products-intro__modal')) {
            const bsModal = Bootstrap.Modal.getInstance(modal);
            bsModal.hide();
          }
        };
        document.addEventListener('click', handleClickOutside, { once: true });
      });
    }
  };

  handleOutsideClick('#x-products-intro__slider-images');
  handleOutsideClick('#x-products-intro__slider-lifestyle-images');
  handleOutsideClick('#x-products-intro__slider-videos');
  handleOutsideClick('#shareeModal');
});
