/**
 * sidebar.ts
 */

export class Sidebar {

    /**
     * Constructor
     **/
    constructor() {
		this.openSidebar();
    }

    /**
     * Function openSidebar
     * Opens sidebar on click
     */
    protected openSidebar(): void {
        const sidebarIcons = document.querySelectorAll('.l-sidebar__icon');

        sidebarIcons?.forEach(icon => {
            icon.addEventListener('click', () => {
                const sidebarItems = document.querySelectorAll('.l-sidebar__item');
                
                sidebarItems.forEach(item => {
                    if (item !== icon.parentElement) {
                        item.classList.remove('open');
                    }
                });

                (icon.parentElement as HTMLElement).classList.toggle('open');
            }, {passive: true});
        });
    }
}
